/* Color Pallete
* --------------------------------------- */
:root {
  --color-flat-white: #f9f9f9;
  --color-flat-black: #000604;

  --color-blue: #092fff;
  --color-blue-500: #5670ff;
  --color-blue-100: #dae0ff;
  --color-blue-50: #eef1ff;

  --color-red-900: #be0035;
  --color-red-800: #d1033d;
  --color-red-700: #e60040;
  --color-red-600: #f61050;
  --color-red-50: #fff3f3;

  --color-purple: #694FFF;
  --color-purple-50: #F0EDFF;
  --color-purple-100: #E5E2FB;

  --color-green: #179B66;
  --color-green-50: #E8F5F1;
  --color-green-100: #D8EFE8;

  --color-grey-900: #363839;
  --color-grey-800: #414244;
  --color-grey-700: #5a5c5e;
  --color-grey-600: #919597;
  --color-grey-500: #adb2b4;
  --color-grey-400: #cbd0d1;
  --color-grey-300: #d9dee0;
  --color-grey-200: #e1e5e6;
  --color-grey-100: #edeff0;
  --color-grey-50: #f9f9f9;

  --color-studio-gradient: linear-gradient(91.86deg,
      #b6c2ff 0%,
      #eda9df 25.72%,
      #f9935a 55.73%,
      #e94c53 92.61%) !important;
  --color-orange-gradient: linear-gradient(97.22deg, #ffb545 0%, #ff5833 100%) !important;
  --color-bindus-gradient: linear-gradient(97.22deg, #915cff 0%, #5670ff 100%) !important;

  --color-primary: #092fff;
  --color-primary-light: #5670ff;

  --color-danger: #e60040;

  --color-text-regular: #000604;
  --color-text-secondary: #5a5c5e;
  --color-text-info: #919597;
  --color-text-placeholder: #adb2b4;

  --color-border: #d9dee0;
  --color-border-light: #edeff0;
  --color-border-dark: #1f2e3d;
}

/* Text Color
* --------------------------------------- */
.color-white {
  color: white !important
}

.color-green {
  color: var(--color-green) !important;
}

.color-primary {
  color: var(--color-primary) !important;
}

.color-primary-light {
  color: var(--color-blue-500) !important;
}

.color-danger {
  color: var(--color-danger) !important;
}

.color-text-regular {
  color: var(--color-flat-black) !important;
}

.color-grey-400 {
  color: var(--color-grey-400) !important;
}

.color-grey-500 {
  color: var(--color-grey-500) !important;
}

.color-grey-600 {
  color: var(--color-grey-600) !important;
}

.color-grey-700 {
  color: var(--color-grey-700) !important;
}

.color-grey-800 {
  color: var(--color-grey-800) !important;
}

.color-text-info {
  color: var(--color-grey-600) !important;
}

.color-text-secondary {
  color: var(--color-grey-700) !important;
}

.color-red-700 {
  color: var(--color-red-700) !important;
}

/* Background Color
* --------------------------------------- */
.bg-flat-white {
  background-color: var(--color-flat-white) !important;
}

.bg-blue-500 {
  background-color: var(--color-blue-500) !important;
}

.bg-white {
  background: white !important;
}

.bg-black {
  background: black;
}

.bg-color-blue {
  background-color: var(--color-blue) !important;
}

.bg-color-blue-500 {
  background-color: var(--color-blue-500) !important;
}

.bg-color-blue-100 {
  background-color: var(--color-blue-100) !important;
}

.bg-color-blue-50 {
  background-color: var(--color-blue-50) !important;
}

.bg-color-red-900 {
  background-color: var(--color-red-900) !important;
}

.bg-color-red-800 {
  background-color: var(--color-red-800) !important;
}

.bg-color-red-700 {
  background-color: var(--color-red-700) !important;
}

.bg-color-grey-900 {
  background-color: var(--color-grey-900) !important;
}

.bg-color-grey-800 {
  background-color: var(--color-grey-800) !important;
}

.bg-color-grey-700 {
  background-color: var(--color-grey-700) !important;
}

.bg-color-grey-600 {
  background-color: var(--color-grey-600) !important;
}

.bg-color-grey-500 {
  background-color: var(--color-grey-500) !important;
}

.bg-color-grey-400 {
  background-color: var(--color-grey-400) !important;
}

.bg-color-grey-300 {
  background-color: var(--color-grey-300) !important;
}

.bg-color-grey-200 {
  background-color: var(--color-grey-200) !important;
}

.bg-color-grey-100 {
  background-color: var(--color-grey-100) !important;
}

.bg-color-grey-50 {
  background-color: var(--color-grey-50) !important;
}

.bg-color-studio-gradient {
  background: var(--color-studio-gradient) !important;
}

/* Border Color
* --------------------------------------- */
.border {
  border: 1px solid var(--color-border) !important;
}

.border-light {
  border: 1px solid var(--color-border-light) !important;
}

.border-dark {
  border: 1px solid var(--color-border-dark) !important;
}