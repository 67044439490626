@mixin layout-padding {
  padding: 24px;

  @media screen and (max-width: $mobile-l-width-1) {
    padding: 16px;
  }
}

@mixin layout-margin {
  margin: 24px;

  @media screen and (max-width: $mobile-l-width-1) {
    margin: 16px;
  }
}

@mixin shadow($size: 7px) {
  background: #ffffff;
  box-shadow: 0px $size $size * 3.428 rgba(54, 56, 57, 0.2);
  border-radius: $size;
}

@mixin shadow-blue($size: 7px) {
  box-shadow: 0px $size $size * 3.428 rgba(9, 47, 255, 0.2);
  border-radius: $size;
}

@mixin shadow-pink($size: 7px) {
  box-shadow: 0px $size $size * 3.428 rgba(230, 0, 64, 0.2);
  border-radius: $size;
}