.studio-invite-popup {


  .search-result-box {
    max-height: 228px;
    overflow-y: auto;
  }

  .search-result-footer {
    padding: 20px 16px 0;
    flex-flow: column;
    align-items: baseline !important;

    .copy-link-wrap {
      margin-top: 6px;
      background-color: var(--color-grey-50);
      width: 100%;
      padding: 6px 6px 6px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      white-space: nowrap;

      >p {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {
    padding: 30px 24px !important;

    .title,
    .search-input>input {
      box-sizing: border-box;
    }

    .search-result-box {
      position: fixed;
      top: 120px;
      left: 0;
      right: 0;
      bottom: 88px;
      padding: 6px 16px 20px;
      box-sizing: border-box;
      max-height: none;
    }

    .search-result-box>ul>li {
      padding: 0;
    }

    .search-result-footer {
      padding: 20px 0;
    }
  }
}