#login-popup,
#signup-popup {
  padding-top: 40px;
  z-index: 91;
  border-radius: 15px;

  p {
    text-align: left;
  }

  .desc {
    margin-bottom: 8px;
    text-align: center;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      margin-left: 8px;
      color: var(--color-primary);
    }
  }

  .agreement-area {
    .ico-next-arrow {
      margin-left: 9px;
      width: 17px;
      height: 17px;
      background: url(/assets/img/ico/next_arrow_bk.svg) center no-repeat;
      background-size: contain;
      display: inline-block;
    }

    a {
      display: flex;
      align-items: center;
    }
  }

  .kakao-btn {
    background: #fee500;
    color: black;
    border: none;

    &:hover {
      background: #fee500;
    }
  }
}