.studio-page {

  @media screen and (max-width: $mobile-l-width-1) {
    overflow: visible;
  }

  >.container {
    margin: 30px auto;
    padding-bottom: 50px;
    width: 96%;
    max-width: 1338px;
    display: flex;
    flex-flow: row;
    gap: 24px;

    .left-area {
      width: 390px;

      .box {
        border: 1px solid var(--color-grey-100);
      }
    }

    .main-area {
      width: 100%;
      max-width: 918px;
    }

    .tabs {
      position: sticky;
      top: 0;
      left: 0;
    }


    .box {
      position: relative;
      margin-top: 12px;
      background: white;
      padding: 30px 16px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .content-box-wrap {
      @media screen and (min-width: $mobile-l-width) {
        margin-top: 12px;
      }

      @media screen and (max-width: $mobile-l-width-1) {
        padding-bottom: 100px;
      }
    }

    .profile-box {
      @media screen and (min-width: $mobile-l-width) {
        border: 1px solid var(--color-grey-100);
      }
    }

    @media screen and (max-width: $mobile-l-width-1) {
      margin: 0;
      padding: 0;
      flex-flow: column;
      gap: 0;
      width: 100%;
      background: white;

      .side-area {
        padding-bottom: 16px;

        .profile-box {
          padding: 30px 24px 0;
        }
      }

      .main-area {
        padding: 0;

        .reference-items {
          padding: 0 16px;
          box-sizing: border-box;
        }

        .empty-view {
          height: 300px;
        }
      }

      .side-area,
      .main-area {
        max-width: none;
        width: 100%;
        box-sizing: border-box;
      }

      .edit-btn {
        margin: 16px;
      }
    }

    @media screen and (min-width: $mobile-l-width) {
      .side-area {
        min-width: 390px;
        max-width: 390px;
      }

      .empty-view {
        height: 500px;
      }

      .editor-viewer {
        .img-sec img {
          min-width: 870px;
        }
      }
    }
  }

  .reference-items {
    display: grid;
    width: 100%;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);

    @media screen and (min-width: $mobile-l-width) {
      grid-template-columns: repeat(2, 1fr);
    }

    >li {
      cursor: pointer;

      &.creative {
        .category span {
          background-color: var(--color-purple-50);
          color: var(--color-purple);
        }
      }

      &.tech {
        .category span {
          background-color: var(--color-green-50);
          color: var(--color-green);
        }
      }

      .thumb-wrap {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 68.8%;
        background-color: #d9d9d9;
        overflow: hidden;
        border-radius: 10px;


        // &::after {
        //   display: block;
        //   content: ' ';
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   background: rgba(0, 0, 0, 0.5);
        // }

        >img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 101%;
          height: 101%;
          transform: translate(-50%, -50%);
          object-fit: cover;

          &:hover {
            transition: all 0.2s ease-in-out;
            width: 104%;
            height: 104%;
          }
        }

      }
    }

    .category {
      margin-top: 13.5px !important;
    }

    .title {
      margin-top: 8px !important;
    }

    .company {
      margin-top: 9px;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.66;
      height: 40px;
      color: #70748e;
    }
  }
}


.studio-edit-page {
  padding-bottom: 50px;

  section {
    margin-top: 20px;
    position: relative;
    background: white;
    color: var(--color-grey-800);
    border: 1px solid var(--color-grey-400);
    border-radius: 5px;
    overflow: hidden;

    &:first-of-type {
      margin-top: 0;
    }

    .control-btn-wrap {
      position: absolute;
      top: 16px;
      right: 16px;
      display: flex;
      z-index: 9;

      >button {
        position: relative;
        width: 36px;
        height: 36px;
        cursor: pointer;
        border: none;
        outline: none;
        overflow: hidden;
        border-radius: 18px;

        &::before {
          position: absolute;
          display: block;
          content: " ";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          transition: all 0.2s ease-in-out;
          opacity: 0;
        }

        &:not([disabled]):hover::before {
          opacity: 1;
        }

        &.delete-btn {
          background: url(/assets/img/studio/icon_studio_edit_btn_delete.svg);
        }

        &.up-btn {
          background: url(/assets/img/studio/icon_studio_edit_btn_up.svg);

          &:disabled {
            background: url(/assets/img/studio/icon_studio_edit_btn_up_disable.svg);
          }
        }

        &.down-btn {
          background: url(/assets/img/studio/icon_studio_edit_btn_down.svg);

          &:disabled {
            background: url(/assets/img/studio/icon_studio_edit_btn_down_disable.svg);
          }
        }
      }
    }
  }

  input {
    outline: none;
    border: none;
  }

  .title-section {
    position: relative;
    height: 68px;
    display: flex;
    align-items: center;

    input {
      padding-left: 16px;
      margin-right: 169px;
      width: 100%;
      height: 100%;
    }
  }

  .vid-section,
  .img-section,
  .caption-img-section {
    position: relative;
    background: var(--color-grey-100);

    >.container {
      padding: 68px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-flow: column;
    }

    .upload-btn {
      display: flex;
      gap: 6px;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      width: 162px;
      height: 40px;
      background: white;
      border: none;
      outline: none;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        background: var(--color-grey-50);
      }

      >i {
        width: 24px;
        height: 24px;

        &.ico-video {
          background: url(/assets/img/studio/icon_widget_video_add.svg) no-repeat center / contain;
        }

        &.ico-image {
          background: url(/assets/img/studio/icon_widget_pic_add.svg) no-repeat center / contain;
        }
      }
    }

    .input {
      width: 100%;
      max-width: 590px;
    }

    .vid-wrap {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      overflow: hidden;

      >iframe {
        position: absolute;
        top: -0.5%;
        left: -0.5%;
        width: 101%;
        height: 101%;
      }
    }
  }

  .text-section {
    min-height: 68px;

    >textarea {
      padding: 24px 16px;
      border: none;
      outline: none;
      width: 100%;
      box-sizing: border-box;
      resize: vertical;
    }
  }

  .img-section {
    position: relative;
  }

  .caption-img-section {
    .caption-item-button {
      width: 24px;
      height: 24px;
      background: url(/assets/img/studio/icon_plus_black.svg) no-repeat center / contain;
      background-size: 20px;
      background-color: var(--color-red-700);
      border-radius: 12px;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      outline: none;
      border: none;

      &:hover {
        background-color: var(--color-red-600);
      }
    }

    .caption-item-content {
      position: relative;
      margin: 10px;
      box-sizing: border-box;
      width: 274px;
      height: 104px;

      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        border: none;
        outline: none;
        z-index: 9;
        background: white;
        cursor: pointer;

        @include icon-close(11px,
          1.2px,
          var(--color-flat-black),
          3px,
          inline-block,
          absolute,
          1px);
      }

      >.content {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        padding: 16px;
        box-sizing: border-box;
        background: #ffffff;

        textarea {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 10px 0;
          box-sizing: border-box;
          outline: none;
          border: none;
          resize: none;
        }
      }

      &::before {
        position: absolute;
        display: block;
        content: " ";
        border-top: 24px solid white;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 0px solid transparent;
        background: transparent;
      }

      &[class^="top"]::before,
      &[class*=" top"]::before {
        top: 0;
        transform: rotate(180deg);
      }

      &[class^="bot"]::before,
      &[class*=" bot"]::before {
        bottom: 0;
      }

      &[class$="-start"]::before {
        left: 27px;
      }

      &[class$="-center"]::before {
        left: 50%;
        margin-left: -5px;
      }

      &[class$="-end"]::before {
        right: 27px;
      }
    }
  }

  .add-widget-section {
    padding: 16px 16px 20px;
    text-align: center;

    ul {
      margin: 16px auto 0;
      padding-top: 20px;
      border-top: 1px solid var(--color-grey-200);
      display: flex;
      gap: 30px;
      align-items: center;
      justify-content: center;

      >li {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 80px;
        background: var(--color-grey-50);
        border: 1px solid var(--color-grey-200);
        border-radius: 5px;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
          background: var(--color-grey-100);
        }

        >i {
          width: 48px;
          height: 48px;

          &.ico-widget-title {
            background: url(/assets/img/studio/icon_widget_title_add.svg) no-repeat center / contain;
          }

          &.ico-widget-video {
            background: url(/assets/img/studio/icon_widget_video_add.svg) no-repeat center / contain;
          }

          &.ico-widget-text {
            background: url(/assets/img/studio/icon_widget_text_add.svg) no-repeat center / contain;
          }

          &.ico-widget-pic {
            background: url(/assets/img/studio/icon_widget_pic_add.svg) no-repeat center / contain;
          }

          &.ico-widget-caption {
            background: url(/assets/img/studio/icon_widget_caption_add.svg) no-repeat center / contain;
          }

          &.ico-widget-link {
            background: url(/assets/img/studio/icon_widget_link_add.svg) no-repeat center / contain;
          }
        }
      }

      @media screen and (max-width: $mobile-l-width-1) {
        display: grid;
        width: 240px;
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {
    padding: 16px;

    .box-container {
      padding: 0;
    }
  }

  .link-select-popup {
    padding: 50px 90px;

    @media screen and (max-width: $mobile-l-width-1) {
      padding: 50px 59px;
    }

    ul {
      display: flex;
      justify-content: center;
      gap: 20px;

      >li {
        width: 110px;
        height: 110px;
        min-width: 110px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        border: 1px solid var(--color-grey-200);
        background-color: var(--color-grey-50);
        cursor: pointer;
        transition: all .2s ease-in-out;
        border-radius: 5px;

        >i {
          width: 48px;
          height: 48px;

          &.ico-widget-text-link {
            background: url(/assets/img/studio/icon_widget_text_link.svg) no-repeat center / contain;
          }

          &.ico-widget-image-link {
            background: url(/assets/img/studio/icon_widget_pic_link.svg) no-repeat center / contain;
          }
        }

        &:hover {
          border: 1px solid var(--color-primary);
        }
      }
    }
  }
}