.guide-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  height: 100%;
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;

  @media screen and (max-width: $tablet-s-width-1) {
    .pc-only {
      display: none !important;
    }

    .mobile-only {
      display: block !important;
    }

    .message-box {
      margin-top: 20px;
      position: relative;
      width: 332px;

      >img {
        width: 100%;
      }

      .message-box-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        text-align: left;
      }

      .message-box-footer {
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
        display: flex;
        white-space: nowrap;
        gap: 6px;
      }
    }

    .step-1 {
      position: fixed;
      bottom: 80px;
      left: 10px;
      right: 10px;

      .img {
        width: 100%;
      }
    }

    .step-2 {
      position: fixed;
      top: 20px;
      left: 20px;

      .img {
        width: 144px;
      }
    }

    .step-3 {
      position: fixed;
      top: 20px;
      right: 20px;
      text-align: right;

      .img {
        width: 202px;
      }

      .message-box {
        margin-top: 7px !important;
      }

    }

    .detail-guide {
      position: relative;
      margin: 0 auto;
      width: 100%;
      max-width: 1400px;
      // background: red;
      height: 100vh;


      .step-4,
      .step-5,
      .step-6 {
        position: fixed;
        left: 20px;
        right: 20px;
        bottom: 60px;
        text-align: right;
        display: flex;
        flex-flow: column-reverse;
        gap: 20px;

        >div {
          text-align: right;
        }

        .img {
          width: 172px;
          float: right;
        }

        .message-box {
          float: right;
        }
      }

      .step-4 .message-box {
        margin-top: -22px;
      }

      .step-5 .message-box {
        margin-top: 26px;
      }

      .step-6 .message-box {
        margin-top: 74px;
      }
    }



  }

  @media screen and (min-width: $tablet-s-width) {
    padding: 0 90px;

    .pc-only {
      display: block !important;
    }

    .mobile-only {
      display: none !important;
    }

    .step-1 {
      position: fixed;
      top: 180px;
      left: 50%;
      transform: translateX(-50%);

      .img {
        width: 644px;
      }
    }

    .step-2 {
      position: fixed;
      top: 20px;
      left: 20px;

      .img {
        width: 144px;
      }
    }

    .step-3 {
      position: fixed;
      top: 20px;
      right: 20px;
      text-align: right;

      .img {
        width: 202px;
      }
    }

    .detail-guide {
      position: relative;
      margin: 0 auto;
      width: 100%;
      max-width: 1400px;
      // background: red;
      height: 100vh;
      box-sizing: border-box;

      .step-4,
      .step-5,
      .step-6 {
        position: absolute;
        top: 200px;
        right: -85px;
        width: 436px;
        height: 214px;

        .img {
          position: absolute;
          right: 0;
          width: 76px;
        }
      }

      .step-4 .message-box {
        margin-top: -22px;
      }

      .step-5 .message-box {
        margin-top: 26px;
      }

      .step-6 .message-box {
        margin-top: 74px;
      }
    }


    .message-box {
      position: relative;
      margin-top: 20px;
      width: 332px;

      >img {
        width: 100%;
      }

      .message-box-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        text-align: left;
      }

      .message-box-footer {
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
        display: flex;
        white-space: nowrap;
        gap: 6px;
      }
    }
  }
}