.profile-img-wrap {
  position: relative;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
  background-size: cover;
  display: inline-block;

  &.user {
    background: url(/assets/img/ico/profile_basic.svg) center no-repeat;
    background-size: cover;
  }

  &.lg {
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    height: 100px;
  }

  >img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }

  .initial {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: white;
    font-weight: bold;
    background: linear-gradient(97.22deg, #3353FF 0%, #5670FF 100%);

    &.bg-type1 {
      background: linear-gradient(97.22deg, #915CFF 0%, #90A2FF 100%);
    }

    &.bg-type2 {
      background: linear-gradient(97.22deg, #EA567E 0%, #F595AF 100%);
    }

    &.bg-type3 {
      background: linear-gradient(97.22deg, var(--color-grey-900) 0%, var(--color-grey-600) 100%);
    }
  }

  .change-btn {
    position: absolute;
    right: -9px;
    bottom: 0px;
    width: 36px;
    height: 36px;
    background: url(/assets/img/ico/profile_camera.svg) no-repeat center / cover;
    background-color: white;
    border: 1px solid var(--color-grey-400);
    border-radius: 18px;
    cursor: pointer;
  }
}

.user-profile-area {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 14px;

  .profile-img-wrap {
    cursor: pointer;
  }

  .badge {
    font-size: 11px;
    background: var(--color-grey-500);
    color: white;
    padding: 2px 4px;
    border-radius: 12px;
  }

  &.sm {
    height: 108px;
    flex-flow: row;
  }

  &.md {
    flex-flow: row;
    gap: 30px;

    .profile-img-wrap {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
  }

  &.mobile-row {
    @media screen and (max-width: $mobile-l-width-1) {
      flex-flow: column !important;
      text-align: center;
    }
  }
}

.studio-profile-area {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .profile-img-wrap {
    margin-top: 24px;
  }
}

.profile-social-list {
  display: flex;
  flex-flow: column;
  gap: 10px;

  >li {
    padding: 6px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-grey-50);

    &.link {
      color: var(--color-flat-black);
      background-image: url(/assets/img/ico/sidebar_arrow.svg);
      background-position: center right 6px;
      background-repeat: no-repeat;
      background-size: 30px 30px;
    }
  }
}

.company-medals {
  position: absolute;
  top: 0;

  &.right {
    right: 50px;

    @media screen and (max-width: $mobile-l-width-1) {
      right: 30px;
    }
  }

  &.left {
    left: 50px;

    @media screen and (max-width: $mobile-l-width-1) {
      left: 30px;
    }
  }

  img {
    width: 46px;
    height: 46px;
  }
}