.message-page {
  overflow: visible !important;

  .filter-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    .custom-label {
      width: 100%;
      text-align: right;
    }
  }

  .top-area {
    z-index: 2;
    padding-bottom: 40px !important;

    .new-message-btn {
      margin-top: 20px;
      width: 162px;
      z-index: 9;
    }

    @media screen and (min-width: $mobile-l-width) {
      display: none !important;
    }
  }

  .tab-area {
    position: sticky !important;
    top: 0;
    background: var(--color-grey-50);
    z-index: 8;
  }

  .tabs {
    @media screen and (min-width: $mobile-l-width) {
      padding-top: 40px;
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {

    .top-area,
    .tabs {
      background: white;
    }
  }

  @media screen and (min-width: $mobile-l-width) {
    .new-message-btn {
      position: absolute;
      margin-top: 0;
      top: 30px;
      right: 45px;
      width: 162px;
      z-index: 9;
    }
  }

  .container {
    position: relative;
    display: block;
    margin: 0 auto;
    max-width: 1476px;
    padding: 0 45px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    .message-list {
      display: grid;
      gap: 40px;
      grid-template-columns: repeat(4, 1fr);

      >li {
        position: relative;
        background: white;
        padding: 26px 24px 24px;
        transition: all .2s ease-in-out;
        cursor: pointer;
        border: 1px solid var(--color-grey-100);

        &:hover {
          border: 1px solid var(--color-primary);
        }

        >div {
          display: flex;

          * {
            line-height: 20px !important;
          }

          &:first-of-type {
            padding-bottom: 7px;
          }

          >div:first-of-type {
            width: 68px;
          }
        }

        &.new::after {
          position: absolute;
          top: 8px;
          right: 8px;
          display: block;
          content: 'NEW';
          padding: 2px 6px;
          border-radius: 12px;
          background-color: var(--color-red-700);
          color: white;
          font-size: 11px;
          font-weight: 600;
          line-height: 15px;
        }
      }
    }

    @media screen and (max-width: $mobile-l-width-1) {
      padding: 0;


      .message-list {
        margin: 0 0 50px;
        padding: 0 16px;
        gap: 12px;
        grid-template-columns: repeat(1, 1fr);
      }

      .filter-wrap {
        padding: 0 16px;
      }
    }

    @media (min-width: $mobile-l-width) and (max-width: $tablet-l-width) {
      .message-list {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media (min-width: $tablet-l-width) and (max-width:1280px) {
      .message-list {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

.show-aside {
  @media (min-width: $tablet-s-width) and (max-width: 1280px) {
    .message-list {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  @media (min-width: $desktop-s-width) and (max-width:$desktop-l-width) {
    .message-list {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }
}

#message-detail-popup {
  width: 92%;
  max-width: 600px;
  padding: 24px;
  box-sizing: border-box;

  .items {
    margin-top: 16px;

    >li {
      display: flex;
      margin-top: 10px;

      &:first-of-type {
        margin-top: 0;
      }

      >div:first-of-type {
        width: 68px;
      }

      p {
        margin-bottom: 4px;
        display: flex;
        gap: 6px;
      }
    }
  }

  .reply-btn {
    background: url(/assets/img/message_reply.svg) center no-repeat;
    background-size: cover;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .content {
    margin-top: 24px;
    width: 100%;
    min-height: 160px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px 12px;
    box-sizing: border-box;
    white-space: pre-line;
  }

  .ok-btn {
    width: 146px;
  }

  @media screen and (max-width: $mobile-l-width-1) {
    .ok-btn {
      width: 100%;
    }
  }
}

#send-message-popup {
  width: 100%;
  max-width: 600px;
  padding: 24px;
  box-sizing: border-box;

  textarea {
    height: 170px;
    resize: none;
  }

  .btn-wrap {
    display: flex;

    @media screen and (min-width: $mobile-l-width) {
      justify-content: flex-end;

      .btn {
        width: 146px;
        flex-grow: 0;
      }
    }
  }
}