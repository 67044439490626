.textLinkBlock {
  padding: 24px 16px;
  background: var(--color-grey-100);

  button {
    display: flex;
    gap: 6px;
    color: var(--color-grey-800);
    transition: all .2s ease-in-out;

    &:disabled {
      background-color: white;
      border: 1px solid var(--color-grey-300);
      color: var(--color-grey-500);
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.insertedBlock {
  position: relative;
  background-color: var(--color-grey-100);
  padding: 24px 38px;
  color: var(--color-blue);

  word-break: break-all;

  padding-right: 68px;

  &.menuOpened {
    padding-right: 170px;
  }

  &::before {
    position: absolute;
    display: block;
    content: ' ';
    top: 26px;
    left: 16px;
    width: 16px;
    height: 16px;
    background: url(/assets/img/studio/icon_link_popup.svg) no-repeat center / cover;
  }

  a {
    color: var(--color-blue);
  }
}