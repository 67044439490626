.user-library-page {
  position: relative;
  height: 100%;

  .box-container {
    position: relative;

    padding: 0 24px;
    max-width: 1556px;
    box-sizing: border-box;
  }

  .tab-area {
    position: sticky;
    top: 0;
    padding-top: 40px;
    z-index: 8;
    background-color: var(--color-grey-50);
    margin-bottom: 30px;

    @media screen and (max-width: $mobile-l-width-1) {
      padding-top: 0;
      margin-bottom: 16px;
    }
  }

  .filter-area {
    margin-top: -16px !important;
    padding-top: 16px !important;
    z-index: 8;
    position: sticky;
    background: var(--color-grey-50);
    padding-bottom: 13px !important;

    @media screen and (max-width: $mobile-l-width-1) {
      top: 48px;
    }

    @media screen and (min-width: $mobile-l-width) {
      top: 88px;
    }
  }

  .company-list {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(5, 1fr);

    @media (min-width: 1536px) and (max-width: 1920px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 1024px) and (max-width: 1536px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 12px;
      padding: 0 16px;
    }


    >li {
      padding: 24px;
      background: white;
      height: 124px;
      cursor: pointer;
      transition: all .2s ease-in-out;
      border: 1px solid var(--color-grey-100);

      .top-area {
        display: flex;
        gap: 18px;
        height: 60px;
        align-items: flex-start;
      }

      .tags {
        margin-top: 19px;

        >li {
          display: flex;
          align-items: center;
          padding: 0 6px;
          margin-right: 4px;
          margin-bottom: 5px;
          font-size: 12px;
          line-height: 1;
          height: 20px;
        }
      }

      @media (hover: hover) {
        &:hover {
          border: 1px solid var(--color-primary);
        }
      }
    }
  }

  .visit-pagination {
    display: flex;
    gap: 10px;
    align-items: center;

    button {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: var(--color-grey-400);
      border: 1px solid var(--color-grey-400);
      background: transparent;
      cursor: pointer;

      &.prev-btn {
        background-image: url(/assets/img/ico/before_arrow_bk.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px;
      }

      &.next-btn {
        background-image: url(/assets/img/ico/next_arrow_bk.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px;
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-grey-100);
        }
      }
    }
  }

  .reference-items {
    li {
      width: 100%;
      overflow: hidden;

      &.creative {
        .category span {
          background-color: var(--color-purple-50);
          color: var(--color-purple);
        }
      }

      &.tech {
        .category span {
          background-color: var(--color-green-50);
          color: var(--color-green);
        }
      }
    }
  }

  .reference-items.edit-mode {
    li {
      position: relative;

      &::after {
        position: absolute;
        display: block;
        content: ' ';
        top: 12px;
        right: 12px;
        width: 24px;
        height: 24px;
        background: url(/assets/img/ico/circle_check_full.svg) center no-repeat;

      }

      &.selected::after {
        background: url(/assets/img/ico/circle_check_bl_on.svg) center no-repeat;
      }
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {
    .container {
      padding: 0 0 50px;

      .tabs {
        background-color: white;
      }
    }

    .flex-row-space-between,
    .reference-items {
      padding: 0 16px 50px;
      box-sizing: border-box;
    }
  }
}