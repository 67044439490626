.reference-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 80px 90px;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;

  @media screen and (max-width: $tablet-s-width-1) {
    background-color: white;
  }

  &::before {
    position: fixed;
    display: block;
    content: ' ';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: zoom-out;
    z-index: -1;
  }

  .container {
    position: relative;
    margin: 0 auto !important;
    padding: 0 !important;
    display: block;
    max-width: 1400px;
    width: 100%;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity .3s ease-out;

    &:not(.loading) {
      opacity: 1;
    }

    .content-container {
      min-width: 100%;
      z-index: 20;

      .editor-viewer {
        @media screen and (max-width: $tablet-s-width-1) {
          padding: 0 16px 50px;
          border-radius: none !important;
        }

        @media screen and (min-width: $tablet-s-width) {
          border: 1px solid var(--color-grey-200);
          padding: 50px;
        }
      }

      .container-header {
        color: white;

        a {
          color: white;
        }

        .title {
          font-size: 26px;
          line-height: 32px;
          font-weight: 600;
          word-break: break-all;
        }

        .company {
          font-size: 16px;
          line-height: 22px;
        }
      }


      .counter-area {
        display: flex;
        align-items: center;
        height: 24px;

        img {
          width: 16px;
          margin-right: 6px;
        }

        span {
          margin-right: 14px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .box-wrap {
        padding-top: 20px;
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(2, 1fr);

        @media screen and (max-width: $tablet-s-width-1) {
          margin-top: 0;
          grid-template-columns: repeat(1, 1fr);
          gap: 16px;
        }

        .box {
          position: relative;
          margin: 0;
          padding: 24px;
          background: white;
          border: 1px solid var(--color-grey-200);
          border-radius: 10px;
          width: 100%;
          box-sizing: border-box;
          overflow: hidden;

          @media screen and (min-width: $tablet-s-width) {
            min-height: 152px;
          }

          &.counter-box {
            display: flex;

            >div:first-of-type {
              display: flex;
              flex-flow: column;
              flex-grow: 1;
              justify-content: center;
            }

            .counter-area {
              height: 100%;
            }

            @media screen and (max-width: $tablet-s-width-1) {
              padding: 24px;
              gap: 15px;
              min-height: auto;
              flex-flow: column;
            }
          }

          .categories {
            margin-top: 14px;

            @media screen and (min-width: $tablet-s-width) {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 8px;
            }

            >li {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              background: black;
              color: white;
              border-radius: 5px;
              height: 30px;
              font-weight: 500;
              overflow: hidden;

              &::before {
                display: block;
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6);
              }

              span {
                z-index: 9;
              }

              @media screen and (max-width: $tablet-s-width-1) {
                margin-top: 8px;
                width: 100%;

                &:first-of-type {
                  margin-top: 0;
                }
              }
            }
          }

          .tags {
            margin-top: 14px;
          }
        }
      }
    }

    .float-container {
      position: fixed;
      width: 50px;
      z-index: 10;

      @media screen and (max-width: $tablet-s-width-1) {
        top: 30px;
        right: 16px;
      }

      @media screen and (min-width: $tablet-s-width) {
        right: 20px;
        top: 80px;

      }

      @media screen and (min-width: 1580px) {
        left: 50%;
        top: 80px;
        margin-left: 720px;
      }


      .reference-detail-widget {
        .close-btn {
          margin-bottom: 76px;
          width: 50px;
          height: 50px;
          border-radius: 25px;
          background: rgba(255, 255, 255, 0.25);
          border: none;
          outline: none;
          cursor: pointer;
          padding: 7px 7px;
          box-sizing: border-box;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(7.5px);



          @media screen and (max-width: $tablet-s-width-1) {
            @include icon-close(18px,
              2.5px,
              var(--color-flat-black),
              15px,
              inline-block,
              relative,
              2px);
          }

          @media screen and (min-width: $tablet-s-width) {
            @include icon-close(18px,
              2.5px,
              white,
              15px,
              inline-block,
              relative,
              2px);
          }
        }
      }
    }
  }

  .reference-detail-info {
    padding: 16px;
    background-color: #f2f2f2;
    display: flex;
    flex-flow: column;
    gap: 10px;
    border-radius: 5px;
    color: var(--color-flat-black);

    .period-wrap {
      display: flex;
      align-items: center;

      .ico-date {
        margin-right: 12px;
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url(/assets/img/ico/icon_ref_date.svg);
      }
    }

    table {
      margin-top: 2px;
      border-spacing: 0 10px;

      th {
        width: 72px;
        color: var(--color-grey-600);
        vertical-align: top;
      }
    }
  }

  @media screen and (min-width: $tablet-s-width) {

    .action-items {
      width: 50px;
      padding: 7px 7px;
      box-sizing: border-box;
      z-index: 20;
      margin-right: -45px;
      display: flex;
      justify-content: center;
      text-align: center;
      display: grid;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(7.5px);
      border-radius: 25px;

      >li {
        margin-top: 12px;

        &:first-of-type {
          margin-top: 0;
        }

        &:nth-of-type(4) {
          margin-top: 8px;
        }
      }

      button {
        width: 36px;
        height: 36px;
        background-color: white;
        border-radius: 18px;
        border: 1px solid var(--color-grey-100);
        cursor: pointer;

        &:hover {
          background-color: var(--color-grey-50);
        }

        &.message-btn {
          background-image: url(/assets/img/reference_message_btn.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border: none;
        }

        &.like-btn {
          background-image: url(/assets/img/ico/contents_side_btn_like_off.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border: none;

          &.active {
            background-image: url(/assets/img/ico/contents_side_btn_like_on.svg);
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        &.share-btn {
          background-image: url(/assets/img/ico/share_contents_gk.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px;
        }

        &.prev-btn {
          background-image: url(/assets/img/ico/before_arrow_bk.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px;
        }

        &.next-btn {
          background-image: url(/assets/img/ico/next_arrow_bk.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px;
        }
      }

      p {
        margin-top: 4px;
        color: var(--color-grey-400);
        z-index: 9;
        font-weight: 600;
      }
    }
  }

  @media screen and (max-width: $tablet-s-width-1) {
    top: 56px !important;
    padding: 0 !important;
    // background: var(--color-grey-50) !important;
    z-index: 90;

    &::before {
      display: none;
    }

    .close-btn {
      position: fixed !important;
      top: 90px !important;

      // .reference-detail-widget .close-btn {
      //   display: none !important;
      // }
    }

    .action-items {
      position: fixed;
      right: 16px;
      bottom: 70px;
      display: flex;

      padding: 7px 7px;
      box-sizing: border-box;
      z-index: 20;
      display: flex;
      flex-flow: row-reverse;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(7.5px);
      border-radius: 25px;
      gap: 12px;

      button {
        width: 36px;
        height: 36px;
        background-color: white;
        border-radius: 18px;
        border: 1px solid var(--color-grey-100);
        cursor: pointer;

        &:hover {
          background-color: var(--color-grey-50);
        }

        &.like-btn {
          background-image: url(/assets/img/ico/like_contents_off.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px;

          &.active {
            background-image: url(/assets/img/ico/like_contents_on.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 24px;
          }
        }

        &.message-btn {
          background-image: url(/assets/img/reference_message_btn.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border: none;
        }

        &.share-btn {
          background-image: url(/assets/img/ico/share_contents_gk.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px;
        }

        &.prev-btn {
          background-image: url(/assets/img/ico/before_arrow_bk.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px;
        }

        &.next-btn {
          background-image: url(/assets/img/ico/next_arrow_bk.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px;
        }
      }

      p {
        display: none;
      }
    }

    .float-top {
      position: fixed;
      background: url('/assets/img/reference_float_top.svg');
      background-position: center;
      background-repeat: no-repeat;
      left: 50%;
      width: 50px;
      height: 50px;
      margin-left: -25px;
      bottom: 10px;
    }

    .container .content-container .container-header {
      color: var(--color-flat-black) !important;
      padding: 30px 16px 20px;

      a,
      p {
        color: var(--color-grey-600) !important;
      }

      .title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
      }

      .company {
        font-size: 14px;
        line-height: 20px;
      }

      .counter-area {
        display: none;
      }
    }

    .box-wrap {
      margin-top: 0 !important;
      @include layout-padding;
      padding-bottom: 50px !important;
      background-color: var(--color-grey-100);
    }
  }


}