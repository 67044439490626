.studio-reference-page {
  overflow: visible;
  padding-bottom: 88px;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--color-grey-50);

  &.empty-page {
    @media screen and (max-width: $mobile-l-width-1) {
      background-color: white !important;
    }



    .box-container {
      padding: 0 0 50px !important;
    }
  }

  .box-container {
    margin-top: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 50px;

    @media screen and (max-width: $mobile-l-width-1) {
      padding: 0 16px 50px;

      .box {
        border: none;
      }
    }

    .empty-box {
      @media screen and (min-width: $mobile-l-width) {
        margin-top: 40px;
      }
    }
  }

  .filter-wrap {
    position: sticky;
    top: 0px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
    background-color: var(--color-grey-50);
    padding-top: 16px;

    >* {
      z-index: 9;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background-color: var(--color-grey-50);
      content: ' ';
      display: block;
      transform: translateX(-50%);
    }

    @media screen and (min-width: $mobile-l-width) {
      padding-top: 40px;
    }

    .custom-label {
      width: 100%;
      text-align: right;
    }
  }

  .studio-reference-list-item {
    margin: 0 0 12px !important;

    .reference-page {
      z-index: 9;
    }

    &.creative {
      .category span {
        background-color: var(--color-purple-50);
        color: var(--color-purple);
      }
    }

    &.tech {
      .category span {
        background-color: var(--color-green-50);
        color: var(--color-green);
      }
    }
  }

  @media screen and (min-width: $mobile-l-width) {
    .studio-reference-list-item {
      display: flex;
      position: relative;

      &.deactive {

        .thumb-area,
        .content-area p {
          position: relative;

          &::after {
            position: absolute;
            display: block;
            content: ' ';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.5);
            z-index: 1;
          }
        }
      }

      .main-banner-check-area {
        display: flex;
        align-items: center;
        margin-right: 20px;

        label {
          padding-left: 24px;
          cursor: pointer;
        }
      }

      .thumb-area {
        position: relative;
        min-width: 154px;
        height: 100px;
        background-color: var(--color-grey-50);
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;

        >img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);
          object-fit: cover;
        }
      }

      .content-area {
        position: relative;
        padding-left: 12px;
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-flow: column;

        .space {
          flex-grow: 1;
        }

        .select {
          position: absolute;
          left: 196px;
          bottom: 0;
          z-index: 2;
        }
      }

      .button-area {
        position: absolute;
        right: 190px;
        bottom: 30px;
        display: flex;
        gap: 10px;
        z-index: 1;

        .btn {
          .ico {
            width: 14px;
            height: 14px;
          }

          .ico-trash {
            margin: 0;
          }
        }
      }

      .count-area {
        margin-left: 30px;
        padding-left: 30px;
        min-width: 100px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        border-left: 1px solid var(--color-grey-200);
        gap: 14px;

        >p,
        >button {
          padding: 0 24px 0 12px;
          display: flex;
          justify-content: space-between;
          color: var(--color-flat-black);
          border-radius: 5px;

          &.clickable {
            padding: 5px 24px 5px 12px;
            z-index: 2;

            border: 1px solid var(--color-grey-300);
            cursor: pointer;

            background-color: var(--color-grey-100);
            background-image: url(/assets/img/ico/show_more.svg);
            background-repeat: no-repeat;
            background-position: right 8px center;

            &:hover {
              background-color: var(--color-grey-200);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {

    .studio-reference-list-item {
      position: relative;
      display: flex;
      flex-flow: column;
      padding: 30px;

      &.deactive {

        .thumb-area {
          position: relative;

          &::after,
          p:first-of-type {
            position: absolute;
            display: block;
            content: ' ';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.5);
            z-index: 1;
          }
        }

        .content-area>p {
          position: relative;
          display: inherit;

          >span {
            position: relative;

            &::after {
              position: absolute;
              display: block;
              content: ' ';
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.5);
              z-index: 1;
            }
          }
        }
      }

      .main-banner-check-area {
        position: absolute;
        top: 38px;
        right: 38px;
        z-index: 9;

        label {
          padding-left: 24px;
          background-color: white;
          border-radius: 12px;
        }
      }

      .thumb-area {
        position: relative;
        width: 100%;
        height: 192px;
        border-radius: 5px;
        background-color: var(--color-grey-50);
        overflow: hidden;
        cursor: pointer;

        >img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);
          object-fit: cover;
        }
      }

      .content-area {
        padding-top: 20px;
        position: relative;
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-flow: column;

        .select {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }

      .button-area {
        position: absolute;
        top: 38px;
        left: 38px;
        display: flex;
        gap: 10px;
        z-index: 1;

        .btn {
          .ico {
            margin: 0;
            width: 14px;
            height: 14px;
          }

          background: white;
        }
      }

      .count-area {
        margin-top: 20px;
        padding-top: 18px;
        display: flex;
        flex-flow: row;
        justify-content: center;
        border-top: 1px solid var(--color-grey-200);
        width: 100%;
        justify-content: space-between;
        z-index: 1;

        .space {
          height: 19px;
        }

        >p,
        >button {
          padding: 5px 12px;
          display: flex;
          justify-content: space-between;
          color: var(--color-flat-black);
          border-radius: 5px;

          &.clickable {
            padding: 5px 24px 5px 12px;

            border: 1px solid var(--color-grey-300);
            cursor: pointer;

            background-color: var(--color-grey-100);
            background-image: url(/assets/img/ico/show_more.svg);
            background-repeat: no-repeat;
            background-position: right 8px center;

            &:hover {
              background-color: var(--color-grey-200);
            }
          }
        }
      }
    }
  }
}

.reference-saver-layer {
  background: white;
  border-radius: 15px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 168px;
  z-index: 8;

  .title-area {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    padding: 12px 24px 24px;
    box-sizing: border-box;
    background: white;
    z-index: 9;

    &::after {
      display: block;
      content: ' ';
      position: absolute;
      top: -24px;
      left: 0;
      width: 100%;
      height: 24px;
      background: white;
    }

    .close-btn {
      position: absolute;
      top: 12px;
      right: 20px;
      border: none;
      outline: none;
      background: transparent;
      cursor: pointer;

      @include icon-close(14px,
        2px,
        var(--color-flat-black),
        5px,
        inline-block,
        absolute,
        2);
    }
  }


  @media screen and (min-width: $mobile-l-width) {
    padding: 12px 0;
    position: absolute;
    top: 132px;
    left: 792px;
    max-width: 420px;
    max-height: 416px;
    @include shadow();

    .user-list li:hover {
      background-color: var(--color-grey-50);
      cursor: pointer;
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {
    padding: 12px 0 24px;
    border-radius: 10px 10px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    // max-height: -webkit-fill-available;
    box-sizing: border-box;
    z-index: 200;
  }

  .user-list {
    margin-top: 16px;
    background: white;

    >li {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      padding: 0 16px;
    }
  }
}