.full-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 50px;
  height: 50px;
  background: url(/assets/img/spinner.svg) center no-repeat;

  -webkit-animation: spin 1000ms infinite linear;
  -moz-animation: spin 1000ms infinite linear;
  -ms-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;

  &.with-margin {
    margin: 225px auto;
  }

  &.with-small-margin {
    margin: 50px auto;
  }

  &.fixed-center {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  100% {
    -ms-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}