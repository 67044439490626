@import "base/index";
@import "theme/index";

@import "page/layout";
@import "page/home";
@import "page/main";
@import "page/login";
@import "page/reference";
@import "page/studio";
@import "page/studio_visit";
@import "page/create_studio";
@import "page/studio_reference";
@import "page/studio_reference_edit";
@import "page/studio_plan";
@import "page/studio_setting";
@import "page/signup";
@import "page/signup_complete";
@import "page/message";
@import "page/user_profile";
@import "page/user_library";
@import "page/plan";
@import "page/payment";

@import "etc/guide";

.mobile-p-0 {
  @media screen and (max-width:$mobile-l-width-1) {
    padding: 0;
  }
}

.max-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.max-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.customer-popup {
  .ico {
    width: 30px;
    height: 30px;
    margin: 6px 12px;
  }
}

.editor-viewer {
  background: white;
  padding: 24px;
  border-radius: 10px;

  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    background: black;
    overflow: hidden;

    >iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .title-sec {
    &:first-of-type {
      hr {
        display: none;
      }
    }
  }

  .text-link-sec {
    position: relative;
    padding: 0 24px;
    color: var(--color-blue);
    word-break: break-all;

    &::before {
      position: absolute;
      display: block;
      content: ' ';
      top: 3px;
      left: 0;
      width: 16px;
      height: 16px;
      background: url(/assets/img/studio/icon_link_popup.svg) no-repeat center / cover;
    }

    a {
      white-space: pre-line;
      word-break: break-all;
      color: var(--color-blue);
      font-size: 16px;
      line-height: 22px;

      @media (hover:hover) {
        &:hover {
          border-bottom: 1px solid var(--color-blue);
        }
      }

      @media screen and (max-width: $mobile-l-width-1) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    white-space: pre-line;
    word-break: break-all;

    @media screen and (max-width: $mobile-l-width-1) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .text {
    white-space: pre-wrap;
    word-break: break-all;
    font-size: 16px;
    line-height: 22px;

    @media screen and (max-width: $mobile-l-width-1) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  img {
    max-width: 100%;
  }

  .hr {
    margin-top: 40px;
    margin-bottom: 40px;
  }

}

.user-profile-page {
  .box-container {
    margin-top: 100px;

    .profile-img-wrap.user {
      margin-top: -80px;
    }
  }
}