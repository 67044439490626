.tag-search-input {
  position: relative;

  .tag-search-result-container {
    @include shadow();
    position: absolute;
    width: 100%;
    padding: 20px 16px 10px;
    box-sizing: border-box;
    background: white;


    .button-wrap {
      margin-top: 10px;
      max-height: 152px;
      padding: 0 0 6px;
      overflow-y: auto;

      .btn {
        float: left;
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
}

.tag-box {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 9px 4px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid var(--color-grey-400);

  .btn {
    float: left;
    margin: 4px;
    cursor: default;

    .delete-ico {
      width: 0;
      height: 12px;
      background: url(/assets/img/ico/delete_wh.svg) no-repeat center / cover;
      cursor: pointer;
      overflow: hidden;
    }

    &.active {
      background: var(--color-blue-500);
      color: white;

      .delete-ico {
        margin-left: 10px;
        width: 12px;
        opacity: 1;
      }
    }
  }
}

.tags {
  margin-top: 14px;
  overflow: hidden;

  >li {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px 8px;
    height: 20px;
    border: 1px solid var(--color-blue-100);
    border-radius: 5px;
    color: var(--color-blue-500);
    font-size: 14px;
    white-space: nowrap;
  }
}

.tag-search-layer {
  .popup {
    padding: 64px 18px 0;
    display: flex;
    flex-flow: column;
    overflow: hidden;

    .tag-box {
      border: none;
      padding: 0 0 10px;
      overflow: visible;
    }

    .search-tag-area {
      padding-top: 10px;
      padding-bottom: 108px;
      overflow-y: scroll;

      .search-tag-list>li {
        display: flex;
        height: 48px;
        align-items: center;
        padding: 0 6px;
      }
    }
  }
}