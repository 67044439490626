@import "icon";
@import "color";
@import "mixins";
@import "components";

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

html,
input,
textarea {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

a {
  color: var(--color-text-regular);
}

html,
body,
#root {
  height: 100%;
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}