.imageBlock {
  min-height: 14.75rem;
  background: #edeff0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .imageWrapper {
    overflow: hidden;
    position: relative;
    > img {
      display: block;
      width: 100%;
      user-select: none;
    }
  }
  > .uploadGuide {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 1.25rem;
    > p {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.4285714285714286;
      color: #414244;
    }
    > input[type="file"] {
      display: none;
    }
  }
}
