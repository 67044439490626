.editorBlock {
  position: relative;
  color: #000604;
  border: 1px solid var(--color-grey-400);
  border-radius: 5px;
  margin-top: 20px;
  box-sizing: border-box;
  overflow: hidden;

  >.menu {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &:first-of-type {
    margin-top: 0;
  }
}