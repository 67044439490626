.button {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem;
  gap: 0.375rem;
  background: #ffffff;
  border: 1px solid #d9dee0;
  border-radius: 0.3125rem;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375;
  color: #414244;
  cursor: pointer;
  &:hover {
    background: var(--color-grey-50);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
