.home-page {
    padding-bottom: 100px;


    .section-container {
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        max-width: 1240px;
    }


    .thumb-wrap {
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: translate(-50%, -50%);
        }
    }

    .banner-section {
        position: relative;
        background-color: var(--color-grey-500);
        width: 100%;
        height: 380px;

        @media screen and (max-width: $mobile-l-width-1) {
            height: 48.71vw;
        }

        .banner-swiper {
            width: 100%;
            height: 100%;

            .swiper-slide img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
            }
        }

        .section-container {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: absolute;
            margin-top: -24px;
            top: 50%;
            left: 50%;
            right: auto;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: url(/assets/img/main/ico_slide_prev_wh.svg) 16px center no-repeat;
            background-color: rgba(0, 6, 4, 0.25);
            cursor: pointer;
            z-index: 9;
            transition: background-color .2s ease-in-out;

            &:hover {
                background-color: rgba(0, 6, 4, 0.5);
            }

            &::after {
                display: none !important;
            }

            &.swiper-button-disabled {
                display: none;
            }
        }

        .swiper-button-prev {
            margin-left: -638px;
        }

        .swiper-button-next {
            margin-left: 590px;
            transform: rotate(180deg);
        }

        .page-text {
            position: absolute;
            padding: 4px 6px;
            bottom: 16px;
            color: white;
            background: rgba(0, 6, 4, 0.5);
            border-radius: 20px;
            width: auto !important;
            z-index: 9;

            @media screen and (max-width: $mobile-l-width-1) {
                left: auto;
                right: 16px;
            }

            @media screen and (min-width: $mobile-l-width) {
                left: 50%;
                margin-left: 604px;
                transform: translateX(-100%);
            }
        }
    }

    .section {
        .section-title {
            position: relative;
            display: flex;
            color: var(--color-flat-black);
            font-size: 30px;
            font-weight: 600;

            >a:first-of-type {
                display: flex;
                align-items: center;
                gap: 6px;
            }

            .ico-news {
                background: url(/assets/img/main/title_bindus.svg) center no-repeat;
                background-size: cover;
            }

            .ico-creative {
                background: url(/assets/img/main/title_creative.svg) center no-repeat;
                background-size: cover;
            }

            .ico-tech {
                background: url(/assets/img/main/title_tech.svg) center no-repeat;
                background-size: cover;
            }

            .more-link {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 16px;
                line-height: 22px;
                font-weight: 600;
                border: none;
                outline: none;
                background: none;
                display: flex;
                align-items: center;

                &::after {
                    display: inline-block;
                    content: ' ';
                    width: 18px;
                    height: 18px;
                }
            }

            @media screen and (max-width: $mobile-l-width-1) {
                font-size: 24px;
                margin: 0 16px;
                box-sizing: border-box;

                i {
                    width: 30px;
                    height: 30px;
                }
            }

            @media screen and (min-width: $mobile-l-width) {
                font-size: 30px;

                i {
                    width: 36px;
                    height: 36px;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: absolute;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 9;
            transition: background-color .2s ease-in-out;
            border: 1px solid var(--color-grey-200);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

            &:hover {
                background-color: var(--color-grey-200);
                border: 1px solid var(--color-grey-200);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            }

            &::after {
                display: none !important;
            }
        }

        .swiper-button-prev {
            background: url(/assets/img/main/ico_slide_prev_bk.svg) center no-repeat;
            background-color: white;
            left: -18px;
            right: auto;
        }

        .swiper-button-next {
            background: url(/assets/img/main/ico_slide_next_bk.svg) center no-repeat;
            background-color: white;
            left: auto;
            right: -18px;
        }

        .swiper-pagination {
            position: relative;
            bottom: 0 !important;
            z-index: 8;

            .swiper-pagination-bullet {
                margin: 0 4px;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: var(--color-grey-400);
                opacity: 1;
                transition: all .2s ease-in-out;
            }

            .swiper-pagination-bullet-active {
                width: 14px;
                background-color: var(--color-flat-black);
            }
        }

        .category-btn-list-wrap {
            width: 100%;
            margin-top: 40px;

            @media screen and (max-width: $mobile-l-width-1) {
                margin-top: 30px;
            }

            .category-btn-list {
                .swiper-slide {
                    padding: 8px 16px;
                    margin-right: 10px;
                    border-radius: 5px;
                    color: var(--color-flat-black);
                    border: 1px solid var(--color-grey-300);
                    cursor: pointer;
                    width: auto !important;
                    font-size: 16px;
                    font-weight: 600;

                    &.active {
                        color: white;
                    }
                }

                @media screen and (max-width: $mobile-l-width-1) {
                    white-space: nowrap;
                    padding: 0 16px;

                    >li {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .news-section {
        width: 100%;
        background-color: #f6f6f6;

        .news-swiper {

            .swiper-slide {
                width: auto;

                .thumb-wrap {
                    border-radius: 5px;
                    overflow: hidden;
                    border: 1px solid var(--color-grey-300);
                }
            }
        }

        @media screen and (max-width: $mobile-l-width-1) {
            padding: 40px 0;

            .news-swiper {
                margin-top: 30px;
                // padding: 0 16px;

                .swiper-slide:first-of-type {
                    margin-left: 16px;
                }

                .thumb-wrap {
                    width: 157px;
                    height: 157px;
                    background: var(--color-grey-200);
                }
            }
        }

        @media screen and (min-width: $mobile-l-width) {
            padding: 80px 0;

            .news-swiper {
                margin-top: 40px;

                .thumb-wrap {
                    width: 227px;
                    height: 227px;
                    background: var(--color-grey-200);
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                top: 95px;
                margin-top: 0 !important;

                &.swiper-button-disabled {
                    display: none;
                }
            }
        }
    }

    .creative-section {
        .more-link {
            color: var(--color-purple);

            &::after {
                background: url(/assets/img/main/category_arr_pp.svg) center no-repeat;
            }
        }

        .category-btn-list .swiper-slide {
            &:hover {
                background-color: var(--color-purple-50);
            }

            &.active {
                background-color: var(--color-purple);
            }
        }

        .category>span {
            background-color: var(--color-purple-50);
            color: var(--color-purple) !important;
        }

        @media screen and (max-width: $mobile-l-width-1) {
            padding-top: 60px;
        }

        @media screen and (min-width: $mobile-l-width) {
            padding-top: 100px;
        }
    }

    .tech-section {
        padding-top: 60px;

        .more-link {
            color: var(--color-green);

            &::after {
                background: url(/assets/img/main/category_arr_gr.svg) center no-repeat;
            }
        }

        .category-btn-list .swiper-slide {
            &:hover {
                background-color: var(--color-green-50);
            }

            &.active {
                background-color: var(--color-green);
            }
        }

        .category>span {
            background-color: var(--color-green-50);
            color: var(--color-green) !important;
        }

        @media screen and (max-width: $mobile-l-width-1) {
            padding-top: 60px;
        }
    }

    .reference-swiper-wrap {
        position: relative;

        .thumb-wrap {
            cursor: pointer;
            width: 100%;
            border-radius: 5px;
            overflow: hidden;

            @media screen and (max-width: $mobile-l-width-1) {
                height: 114px;
            }

            @media screen and (min-width: $mobile-l-width) {
                height: 190px;
            }
        }

        .title {
            cursor: pointer;
        }

        .swiper-slide ul {
            display: grid;
            gap: 26px;
            grid-template-columns: repeat(4, 1fr);

            @media screen and (max-width: $mobile-l-width-1) {
                padding: 0 16px;
                grid-template-columns: repeat(2, 1fr);
                gap: 24px 10px;
            }

            >li {
                flex-grow: 1;
                width: 100%;

                .category {
                    margin-top: 12px;
                    word-break: break-all;
                }

                .title {
                    margin-top: 6px;
                    word-break: break-all;
                }

                .company {
                    margin-top: 8px;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            margin-top: 0 !important;
            top: 77px;
            transition: none;
        }
    }

    .popup-banner {
        position: fixed;
        padding-bottom: 30px;
        overflow: hidden;
        z-index: 9;
        background: white;
        opacity: 0;
        transition: opacity .2s ease-in-out;

        &.fade-in {
            opacity: 1;
        }

        .popup-banner-header {
            position: relative;
            height: 62px;
            display: flex;
            align-items: center;
            padding: 0 24px;
            box-sizing: content-box;
            justify-content: space-between;
            background: white;

            .close-btn {
                width: 24px;
                height: 24px;
                background: url(/assets/img/main_banner_delete.svg) no-repeat center / contain;
                cursor: pointer;
            }
        }

        .banners {
            list-style: none;
            display: flex;
            flex-flow: column;

            li {
                cursor: pointer;

                img {
                    display: block;
                }
            }
        }

        @media screen and (min-width: $mobile-l-width) {
            top: 86px;
            right: 24px;
            width: 600px;
            border-radius: 10px;
            @include shadow();
        }


        @media screen and (max-width: $mobile-l-width-1) {
            left: 0;
            width: 100%;
            bottom: 0;
            z-index: 9;
            border-radius: 10px 10px 0px 0px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.75));
        }
    }
}