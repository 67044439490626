.ico {
  display: inline-block;
  width: 30px;
  height: 30px;

  &.ico-md {
    width: 24px;
    height: 24px;
  }

  &.ico-sm {
    width: 20px;
    height: 20px;
  }

  &.ico-check {
    background: url(/assets/img/ico/circle_check_bl.svg) center no-repeat;
  }

  &.ico-plus {
    background: url(/assets/img/ico/plus_bl.svg) no-repeat center / contain;
  }

  &.ico-kakao {
    background: url(/assets/img/ico/kakao.svg) no-repeat center / contain;
  }

  &.ico-modify-bl {
    background: url(/assets/img/ico/modify_bl.svg) no-repeat center / contain;
  }

  &.ico-plus-wh {
    background: url(/assets/img/ico/plus_wh.svg) no-repeat center / contain;
  }

  &.ico-image {
    background: url(/assets/img/ico/widget_pic_add.svg) no-repeat center / contain;
  }

  &.ico-trash {
    background: url(/assets/img/ico/trash.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-link-copy {
    background: url(/assets/img/ico/link_copy.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-link-copy-bl {
    background: url(/assets/img/ico/link_copy_bl.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-pencil2 {
    background: url(/assets/img/ico/pencil2.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-error {
    background: url(/assets/img/ico/circle_check_wrong.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-error-line {
    background: url(/assets/img/ico/check_wrong_line.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-unlock {
    background: url(/assets/img/ico/unlock.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-like_contents_on {
    background: url(/assets/img/ico/like_contents_on.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-bookmark {
    background: url(/assets/img/ico/bookmark.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-search-gr {
    background: url(/assets/img/ico/search_gr.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-back-arrow {
    background: url(/assets/img/ico/back_arrow.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-send {
    background: url(/assets/img/ico/send.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-add-home {
    background: url(/assets/img/ico/add_home.svg) center no-repeat;
    background-size: contain;
  }

  &.ico-copy {
    background: url(/assets/img/ico/studio_ref_copy.svg) center no-repeat;
    background-size: contain;
  }
}