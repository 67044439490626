.comment {
  position: absolute;

  .commentButton {
    background: #e60040;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    background: url(/assets/img/ico/plus_wh.svg) center no-repeat;
    background-size: 12px;
    background-color: var(--color-red-700);

    &.opened {
      z-index: 1;

      &::after {
        position: absolute;
        content: "";
        top: calc(50% + 26px);
        left: 50%;
        border-bottom: 1.5rem solid white;
        border-left: 0.75rem solid transparent;
        border-right: 0.75rem solid transparent;
        border-top: 0px solid transparent;
        transform: translate(-50%, -50%) rotate(0);
      }

      &.above::after {
        top: calc(50% - 26px);
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }

  .commentBox {
    position: absolute;
    background: #ffffff;
    border: 1px solid #e1e5e6;
    box-shadow: 0px 7px 24px rgba(54, 56, 57, 0.2);
    border-radius: 0.625rem;
    overflow: hidden;
    width: 15.875rem;
    color: #000604;
    z-index: 1;

    >.topRightButton {
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      padding: 0.5rem 0.625rem 0.375rem;
      border: none;
      outline: none;
      font-size: 0.8125rem;
      line-height: 1.3076923076923077;
      color: #e60040;

      &:disabled {
        cursor: not-allowed;
        color: #5a5c5e;
      }

      cursor: pointer;
    }

    textarea,
    .commentP {
      display: block;
      width: 100%;
      box-sizing: border-box;
      word-break: break-all;
      border: none;
      outline: none;
      resize: none;
      padding: 2rem 0.625rem 1.625rem;
      font-weight: 400;
      font-size: 0.8125rem;
      line-height: 1.3076923076923077;
      font-family: inherit;
      color: inherit;
      white-space: pre-wrap;

      &::placeholder {
        color: #919597;
      }

      &.viewerMode {
        padding: 1.75rem 0.625rem;
      }
    }
  }
}