.reference-edit-page {
  background: var(--color-grey-50);

  @media screen and (max-width: $mobile-l-width-1) {
    background: white;
    padding-bottom: 150px;

    .box-container {
      margin: 0;
      padding: 96px 16px 30px;
      background: white;
    }
  }

  .mobile-header {
    position: absolute;
    top: 56px;
    left: 0px;
    right: 0px;
    z-index: 10;
    padding: 30px 0px 10px;
    margin: 0;
    background: white;
  }

  .mobile-footer {
    >.btn {
      width: auto;
      min-width: 80px;
    }
  }

  .title-input {
    padding: 20px 16px;
    border: 1px solid var(--color-grey-400) !important;
    height: 68px;
    font-weight: 600;
  }

  .image-upload-box {
    position: relative;
    background: var(--color-grey-100);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 230px;
    border: 1px solid #D9DEE0;
    border-radius: 5px;
    overflow: hidden;

    >img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }

    .upload-button {
      background: white;
      width: 160px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-grey-800);
      outline: none;
      border: none;
      font-weight: bold;
      transition: all .2s ease-in-out;
      cursor: pointer;
      z-index: 9;
      border: 1px solid var(--color-grey-300);

      &:hover {
        background: var(--color-grey-50);
      }
    }

    .edit-button {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 36px;
      height: 36px;
      background: url(/assets/img/ico/pencil2.svg) center no-repeat;
      background-color: white;
      background-size: 18px;
      border-radius: 18px;
      cursor: pointer;

      &:hover {
        background-color: var(--color-grey-50);
      }
    }
  }

  .category-select {
    .label {
      border: 1px solid var(--color-grey-400);

      &::after {
        display: none;
      }
    }

    .option-wrap {
      display: flex;
      width: 100%;

      @media screen and (min-width: $mobile-l-width) {
        ul {
          max-height: 360px;
        }
      }
    }
  }

  textarea {
    resize: none;
  }

  .category-list {
    margin-top: 20px;
    width: 100%;
    border-left: 1px solid var(--color-grey-300);
    border-right: 1px solid var(--color-grey-300);
    border-bottom: 1px solid var(--color-grey-300);
    border-radius: 5px;

    >li {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 12px;
      justify-content: space-between;
      height: 44px;
      border-top: 1px solid var(--color-grey-300);
      box-sizing: border-box;

      &:hover {
        background-color: var(--color-grey-50);
        cursor: pointer;
      }

      .radio-area {
        display: flex;
        flex-flow: row;
        align-items: center;

        .badge {
          display: none;
        }

        .ico {
          margin-right: 8px;
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('/assets/img/ico/radio_btn.svg') center no-repeat;
          background-size: cover;
        }
      }

      &.active {
        .radio-area>.ico {
          background: url('/assets/img/ico/radio_btn_on.svg') center no-repeat;
        }

        .badge {
          margin-right: 6px;
          padding: 2px 6px;
          display: inline-block;
          font-size: 12px;
          font-weight: bold;
          background-color: #E60040;
          color: white;
          line-height: 20px;
          border-radius: 12px;
          white-space: nowrap;
        }
      }


      .delete-btn {
        margin-left: 6px;
        display: flex;
        border: none;
        outline: none;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 18px;
        cursor: pointer;

        .ico {
          width: 16px;
        }
      }
    }
  }

  .detail-info-box {
    box-sizing: border-box;

    input {
      padding: 12px 10px;
    }

    textarea {
      padding: 10px;
    }

    textarea.input {
      height: auto;
    }

    &.disabled {
      background-color: var(--color-grey-300);

      p {
        color: var(--color-grey-500)
      }

      input,
      textarea {
        color: var(--color-grey-400)
      }
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {
    textarea {
      min-height: 60px;
    }
  }
}