.main-page {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;

  .container {
    @include layout-padding;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0 auto;
    width: 100%;
    // max-width: 1920px;
    box-sizing: border-box;

    .selected-category-btn {
      //bg-color-blue-500 color-white
      color: white;
      background-color: var(--color-blue-500);

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      i {
        margin-left: 12px;
        width: 24px;
        height: 24px;

        @include icon-close(14px,
          2px,
          white,
          5px,
          inline-block,
          relative,
          2);
      }

      @media screen and (max-width: 320px) {
        span {
          max-width: 20px;
        }
      }

      @media (min-width: 321px) and (max-width: $mobile-l-width-1) {
        span {
          max-width: 70px;
        }
      }
    }

    &.filter-container {
      top: 0;
      position: absolute;
      left: 0;
      background: white;
      z-index: 8;
    }

    .search-keyword {
      margin: 50px auto 30px;
      font-weight: 400;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      text-decoration-line: underline;
      color: var(--color-flat-black);
    }

    .filter-wrap {

      @media screen and (max-width: $mobile-l-width-1) {
        margin: 0;
        padding: 0 0 10px;
      }

      .category-btn-list-wrap {
        position: relative;
        display: block;
        width: 100%;
        background-color: white;
        z-index: 5;

        @media screen and (max-width: $mobile-l-width-1) {
          padding: 20px 0 0 !important;
        }

        @media screen and (min-width: $mobile-l-width) {
          padding: 20px 0 0 !important;
        }

        .category-btn-list {

          .swiper-slide {
            padding: 8px 16px;
            border-radius: 5px;
            color: var(--color-flat-black);
            border: 1px solid var(--color-grey-300);
            cursor: pointer;
            width: auto !important;
            font-size: 16px;
            font-weight: 600;
            border-radius: 5px;

            &.active {
              color: white;
            }

            @media screen and (min-width: $mobile-l-width) {
              display: inline-block;
              margin-top: 10px;
              margin-right: 10px;
            }
          }

          @media screen and (max-width: $mobile-l-width-1) {
            white-space: nowrap;
            margin-top: 10px;

            >li {
              display: inline-block;
            }
          }
        }

        @media screen and (min-width: $mobile-l-width) {
          text-align: center;
        }
      }

      .order-filters {
        padding-top: 14px;
        padding-bottom: 14px;

        @media screen and (max-width: $mobile-l-width-1) {
          padding-top: 20px;
        }
      }

      .type-select {
        @media screen and (max-width: $mobile-l-width-1) {
          min-width: 24px;
        }

        .custom-label {
          margin-left: 100%;
          transform: translateX(-100%);
        }
      }
    }

    .tag-list {
      padding: 14px 0 0;
      display: block;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      min-height: 30px;

      .swiper-slide {
        padding: 0 8px;
        width: auto;
        height: 30px;
        line-height: 30px;
        border-radius: 20px;
        color: var(--color-blue-500);
        border: 1px solid var(--color-blue-500);
        cursor: pointer;
        background-color: white;
        transition: background-color .2s ease-in-out;

        &.active {
          color: white;
          background-color: var(--color-blue-500);
        }

        @media (hover: hover) {
          &:hover {
            color: white;
            background-color: var(--color-blue-500);
          }
        }
      }
    }
  }

  #home-list-container {
    height: 100%;
    overflow-y: auto;
  }

  .transparent-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: block;
  }

  .more-reference-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 270px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 16.55%);
    z-index: 91;
  }
}

.reference-items {
  margin-bottom: 50px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;
  overflow: hidden;

  @media (min-width: $desktop-s-width) and (max-width: $desktop-l-width) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: $tablet-l-width) and (max-width: $desktop-s-width) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: $tablet-s-width) and (max-width: $tablet-l-width) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: $mobile-l-width) and (max-width: $tablet-s-width) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: $mobile-l-width-1) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  >li {
    .thumb-wrap {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 68.8%;
      background-color: #d9d9d9;
      overflow: hidden;
      border-radius: 10px;
      cursor: pointer;

      .pc-only {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        transition: all .2s ease-in-out;

        .like-btn,
        .share-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 36px;
          height: 36px;
          transform: translateY(-50%);
          border-radius: 18px;
          border: none;
          cursor: pointer;
        }

        .like-btn {
          margin-left: -45px;
          background-image: url(/assets/img/ico/like_contents_off.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px;

          &.active {
            background-image: url(/assets/img/ico/like_contents_on.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 24px;
          }
        }

        .share-btn {
          margin-left: 15px;
          background-image: url(/assets/img/ico/share_contents_gk.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px;
        }
      }

      &:hover>.pc-only {
        opacity: 1;
      }

      >img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 101%;
        height: 101%;
        transform: translate(-50%, -50%);
        object-fit: cover;

        &:hover {
          transition: all 0.2s ease-in-out;
          width: 104%;
          height: 104%;
        }
      }
    }
  }

  .category {
    margin-top: 12px;

    span {
      padding: 2px 6px;
      border-radius: 12px;
    }
  }

  .title {
    margin-top: 6px;
    font-weight: 500;
    max-height: 44px;
    color: #111111;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    span {
      cursor: pointer;
      word-break: break-all;
    }
  }


  .company {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    gap: 6px;
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.66;
    min-height: 20px;
    color: #70748e;

    span {
      cursor: pointer;
    }
  }
}

.fold-area {
  overflow: hidden;
  transition: all .2s linear;

  .fold-inner-area {
    @media screen and (max-width: $mobile-l-width-1) {
      padding-top: 20px !important;
    }

    @media screen and (min-width: $mobile-l-width) {
      padding-top: 30px !important;
    }
  }
}

.sub-category-links-wrap {
  width: 100%;
  padding: 16px 16px 4px;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;

  li {
    margin-right: 20px;
    margin-bottom: 12px;
    color: var(--color-flat-black);
    display: inline-block;
    cursor: pointer;

    &.active {
      font-weight: 600;
    }
  }

  @media screen and (min-width: $mobile-l-width) {
    text-align: center;
  }
}

.creative-main-page {
  .category-btn-list .swiper-slide {
    &:hover {
      background-color: var(--color-purple-50) !important;
    }

    &.active {
      background-color: var(--color-purple) !important;
    }
  }

  .sub-category-links-wrap {
    background-color: var(--color-purple-50) !important;

    li.active {
      color: var(--color-purple) !important;
    }
  }

  .tag-list .swiper-slide {
    color: var(--color-purple) !important;
    border: 1px solid var(--color-purple-100) !important;

    &.active {
      border: 1px solid var(--color-purple) !important;
      background: none !important
    }

    @media (hover: hover) {
      &:hover {
        border: 1px solid var(--color-purple) !important;
        background: none !important
      }
    }
  }

  .reference-items .category span {
    background-color: var(--color-purple-50);
    color: var(--color-purple);
  }
}

.tech-main-page {
  .category-btn-list .swiper-slide {
    &:hover {
      background-color: var(--color-green-50) !important;
    }

    &.active {
      background-color: var(--color-green) !important;
    }
  }

  .sub-category-links-wrap {
    background-color: var(--color-green-50) !important;

    li.active {
      color: var(--color-green) !important;
    }
  }

  .tag-list .swiper-slide {
    color: var(--color-green) !important;
    border: 1px solid var(--color-green-100) !important;

    &.active {
      border: 1px solid var(--color-green) !important;
      background: none !important
    }

    @media (hover: hover) {
      &:hover {
        border: 1px solid var(--color-green) !important;
        background: none !important
      }
    }
  }

  .reference-items .category span {
    background-color: var(--color-green-50);
    color: var(--color-green);
  }
}