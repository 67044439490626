/* Button
  * --------------------------------------- */
.btn {
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid var(--color-border);
  color: var(--color-blue-500);
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: background-color 0.15s ease-in-out;
  font-weight: 500;
  padding: 0 12px;
  height: 48px;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.btn-sm {
    padding: 0 15px;
    height: 30px;
    font-size: 14px;
  }

  &.btn-md {
    padding: 0 15px;
    height: 40px;
  }

  @media screen and (max-width: $tablet-s-width) {
    padding: 6px 10px;
  }

  >.ico {
    margin-right: 6px;
    width: 24px;
    height: 24px;
  }

  &:disabled {
    color: #fff;
    background-color: var(--color-grey-400);
    border: 1px solid var(--color-grey-400);
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (hover:hover) {
    &:not(:disabled):hover {
      background-color: var(--color-flat-white);
    }
  }
}

.btn-primary {
  color: #fff;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  @include shadow-blue();

  @media (hover:hover) {
    &:not(:disabled):hover {
      background-color: var(--color-primary-light);
    }
  }
}

.btn-secondary {
  background: var(--color-blue-500);
  border: 1px solid var(--color-blue-500);
  color: white;

  @media (hover:hover) {
    &:not(:disabled):hover {
      background: var(--color-blue-500);
      border: 1px solid var(--color-blue-500);
    }
  }
}

.btn-third {
  background: #f8f9ff;
  border: 1px solid var(--color-blue-500);
  color: var(--color-blue-500);
}

// .btn-third {
//   background: var(--color-blue-50);
//   border: 1px solid var(--color-blue-50);
//   color: var(--color-blue-500);

//   &:hover {
//     background-color: var(--color-blue-50);
//     border: 1px solid var(--color-blue-500);
//   }
// }

.btn-info {
  background: var(--color-grey-900);
  border: 1px solid var(--color-grey-900);
  color: white;
}

.btn-outline-primary {
  color: var(--color-primary);
  background: white;
  border: 1px solid var(--color-primary);

  @media (hover:hover) {
    &:not(:disabled):hover {
      background-color: var(--color-blue-50);
    }
  }
}

.btn-outline-red {
  color: var(--color-red-700);
  background: var(--color-red-50);
  border: 1px solid var(--color-red-700);

  @media (hover:hover) {
    &:not(:disabled):hover {
      background-color: var(--color-red-50);
    }
  }
}

.btn-outline-grey {
  background: transparent;
  border: 1px solid var(--color-grey-300);
  color: var(--color-grey-700);

  @media (hover:hover) {
    &:not(:disabled):hover {
      background-color: var(--color-grey-50);
    }
  }
}

.btn-bindus-gradient {
  background: var(--color-bindus-gradient);
  color: white;
  border: none !important;
}

.btn-orange-gradient {
  background: var(--color-orange-gradient);
  color: white;
  border: none !important;
}

.btn-studio-gradient {
  background: var(--color-studio-gradient);
  box-shadow: 0px 7px 24px rgba(230, 0, 64, 0.2);
  color: white;
  border: none !important;
}