.signup-complete-page {

  @media screen and (min-width: $mobile-l-width) {
    background-color: var(--color-grey-50);

    .page-foot-btn {
      max-width: 358px;
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {
    background-color: white;
  }


  .box-container {
    .box {
      padding: 80px 0;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $mobile-l-width-1) {
        padding: 40px 16px;
        border: none;
      }
    }

    @media screen and (max-width: $mobile-l-width-1) {
      padding: 0 0 80px;
    }
  }


  .favorite-list-wrap {
    overflow-y: scroll;
    padding: 20px 0;
    border-bottom: 1px solid #E1E5E6;

    @media screen and (min-width: $mobile-l-width) {
      max-height: 300px;
    }

    .favorite-list {
      margin: 0 auto;
      overflow: hidden;
      width: 100%;
      max-width: 686px;
      display: grid;
      place-items: center;
      grid-template-columns: repeat(6, 1fr);
      row-gap: 20px;

      @media screen and (max-width: $mobile-l-width-1) {
        grid-template-columns: repeat(3, 1fr);
      }


      >li {
        position: relative;
        display: flex;
        width: 106px;
        height: 108px;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .thumb {
          position: relative;
          margin-bottom: 8px;
          width: 80px;
          height: 80px;
          background: var(--color-grey-200);
          border-radius: 40px;
          overflow: hidden;
          cursor: pointer;
          overflow: hidden;

          >img {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: translate(-50%, -50%);
          }
        }

        &.active {
          .thumb {
            border: 1px solid var(--color-primary)
          }

          &::after {
            position: absolute;
            display: block;
            content: ' ';
            top: 0;
            right: 13px;
            width: 24px;
            height: 24px;
            background: url(/assets/img/ico/circle_check_bl_on.svg) no-repeat center / cover;
          }
        }
      }
    }
  }

  .swiper-pagination {
    margin-top: 3.5px;

    .swiper-pagination-bullet {
      margin: 0 8px !important;
      width: 6px !important;
      height: 6px !important;
      border-radius: 3px !important;
      background-color: var(--color-grey-300) !important;
      opacity: 1 !important;
    }

    .swiper-pagination-bullet-active {
      background-color: var(--color-red-700) !important;
    }
  }
}