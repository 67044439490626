div.text {
  background-color: #ffffff;
  padding: 24px 16px;

  >textarea {
    padding-right: 52px;
    display: block;
    color: inherit;
    font-family: inherit;
    background-color: transparent;
    box-sizing: border-box;
    border: none;
    outline: none;
    resize: none;
    font-weight: 400;
    word-break: break-all;

    &::placeholder {
      color: #adb2b4;
      font-size: inherit;
    }
  }

  &.menuOpened>textarea {
    padding-right: 154px;
  }
}