.tabs {
  position: relative;
  width: 100%;
  height: 48px;
  min-height: 48px;
  display: flex;
  font-size: 16px;
  z-index: 7;

  &::before {
    display: block;
    position: absolute;
    content: " ";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: var(--color-grey-200);
  }

  >li {
    padding: 0 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100%;
    color: var(--color-grey-500);
    border-bottom: 1px solid var(--color-grey-200);
    max-width: 120px;
    white-space: nowrap;
    font-weight: 600;

    &.active {
      color: var(--color-flat-black);

      &::before {
        display: block;
        position: absolute;
        content: " ";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: var(--color-flat-black);
      }
    }

    &:hover {
      background: var(--color-flat-white);
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {
    background: white;

    >li {
      padding: 0 20px;
    }
  }
}