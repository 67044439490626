.imageBlock {
  min-height: 14.75rem;
  background: #edeff0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .imageWrapper {
    overflow: hidden;
    position: relative;

    >img {
      display: block;
      width: 100%;
      user-select: none;
    }
  }

  .linkInputContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 16px;
    display: flex;
    box-sizing: border-box;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.3);

    input {
      width: 100%;
    }

    button {
      display: flex;
      gap: 6px;
      color: var(--color-grey-800);
      transition: all .2s ease-in-out;
      width: 162px;

      &:disabled {
        background-color: white;
        border: 1px solid var(--color-grey-300);
        color: var(--color-grey-500);
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  >.uploadGuide {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 1.25rem;

    >p {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.4285714285714286;
      color: #414244;
    }

    >input[type="file"] {
      display: none;
    }
  }
}