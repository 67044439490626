/* Layer
* --------------------------------------- */
.dim {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

.main-popup-wrap {
  z-index: 200;
}

@media screen and (max-width: $tablet-s-width-1) {
  .mobile-page {
    position: fixed !important;
    top: 56px !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 10 !important;
    overflow-y: auto !important;
  }

  .mobile-full-page {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    bottom: 0 !important;
    z-index: 200 !important;
    overflow-y: auto !important;
  }
}

/* Popup
* --------------------------------------- */
.popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  font-size: 14px;
  transition: all 0.2s ease-in-out;

  &.blur {
    backdrop-filter: blur(2.5px);
  }

  @media screen and (max-width: $mobile-l-width-1) {

    &.mobile-page>.popup,
    &.mobile-full-page>.popup {
      width: 100% !important;
      min-height: 100% !important;
      max-width: 100% !important;
      border-radius: 0 !important;

      .popup-close {
        display: none;

        &.show {
          top: 24px;
          right: 24px;
          display: block;
        }
      }
    }
  }

  .popup {
    position: absolute;
    display: block;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 5px;
    width: 420px;
    height: auto;
    max-width: 90%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    z-index: 100;

    @media screen and (min-width: $mobile-l-width) {
      padding: 60px 40px 40px;
    }


    @media screen and (max-width: $mobile-l-width-1) {
      padding: 40px 24px 24px;
      max-width: 90% !important;
    }

    .popup-title {
      margin: 0 0 12px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: var(--color-flat-black);
    }

    .popup-body {
      color: var(--color-text-regular);
    }

    .close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      border: none;
      outline: none;
      background: transparent;
      cursor: pointer;

      @include icon-close(16px,
        2px,
        var(--color-flat-black),
        4,
        inline-block,
        absolute,
        2);
    }

    .btn-wrap {
      margin-top: 30px;
    }

    &.popup-top {
      top: 80px;
      transform: translateX(-50%);

      @media screen and (max-width: $mobile-l-width-1) {
        top: 30px;
        transform: translateX(-50%);
      }
    }

    &.popup-bottom {
      top: auto;
      bottom: 80px;
      transform: translateX(-50%);

      @media screen and (max-width: $mobile-l-width-1) {
        bottom: 30px;
        transform: translateX(-50%);
      }
    }
  }
}

@keyframes toast-fade-inout {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Toast
* --------------------------------------- */
.toast-wrap {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 420px;
  z-index: 99999999;

  @media screen and (max-width: $mobile-l-width-1) {
    top: 30px;
    left: 16px;
    right: 16px;
    width: auto;
    transform: none;
  }

  .toast {
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    background: white;
    padding: 0 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
    box-sizing: border-box;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    background: white;
    z-index: 999;
    animation: toast-fade-inout 2s linear;
    opacity: 0;

    @media screen and (max-width: $mobile-l-width-1) {
      padding: 0 24px;
    }

    .ico {
      margin-right: 14px;
    }

    @include shadow();
  }
}

/* Push Message
* --------------------------------------- */
.fixed-push-message {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 420px;
  min-height: 44px;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 1px solid var(--color-grey-300);
  border-radius: 10px;
  z-index: 99999999;
  @include shadow();
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 5px 28px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  transition: all 1s ease-in-out;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  @media screen and (max-width: $mobile-l-width-1) {
    top: 30px;
    left: 16px;
    right: 16px;
    width: auto;
    transform: none;
  }
}