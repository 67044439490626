.search-input {
  position: relative;

  .close-btn {
    display: none !important;
  }

  >input {
    width: 100%;
  }

  .search-result-box {
    padding: 18px 0;
    position: absolute;
    width: 100%;
    background: white;

    @media screen and (min-width: $mobile-l-width) {
      @include shadow();
    }

    .company-profile {
      width: 30px;
      height: 30px;
      background: var(--color-bindus-gradient);
      border-radius: 15px;
      margin-right: 12px;
      overflow: hidden;

      .initial {
        font-size: 14px;
      }
    }

    ul>li {
      height: 45px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      cursor: pointer;


      &:hover {
        background-color: var(--color-grey-50);
      }
    }
  }


  @media screen and (max-width: $mobile-l-width-1) {
    &.focus {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      padding: 24px;
      box-sizing: border-box;
      overflow-y: auto;

      .close-btn {
        position: fixed;
        display: block !important;
      }

      input {
        margin-top: 24px;
      }

      .search-result-box {
        position: relative;
        margin-top: 24px;
        padding: 0;

        >div {
          padding: 0;
          height: 48px;
        }
      }
    }
  }
}