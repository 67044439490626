.menu {
  display: flex;
  gap: 0.625rem;

  button {
    width: 36px;
    padding: 0;
    height: 36px;
    font-size: 0;
    border-radius: 50%;
    border: 1px solid #d9dee0;
    background-color: #ffffff;
    outline: none;
    cursor: pointer;
    color: #414244;
    transition: background-color .2s ease-in-out;

    &:hover:not(:disabled) {
      background-color: var(--color-grey-50);
    }

    &.delete,
    &.edit {
      margin-right: 10px;
    }

    &:disabled {
      cursor: not-allowed;
      color: #cbd0d1;
    }
  }
}