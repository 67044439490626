/* Checkbox
  * --------------------------------------- */
.checkbox,
.radio {
  display: none;
  height: 24px;

  &:checked+label {
    background: url("/assets/img/ico/circle_check_bl_on.svg") no-repeat 0 center / contain;
  }

  &+label {
    display: block;
    padding-left: 34px;
    height: 24px !important;
    line-height: 24px !important;
    background: url("/assets/img/ico/circle_check.svg") no-repeat 0 center / contain;
  }

  &.blue {
    &:checked+label {
      background: url("/assets/img/ico/circle_check_bl_on.svg") no-repeat 0 center / contain;
    }

    &+label {
      background: url("/assets/img/ico/circle_check_bl.svg") no-repeat 0 center / contain;
    }
  }

  &.green {
    &:checked+label {
      background: url("/assets/img/ico/circle_check_gn_on.svg") no-repeat 0 center / contain;
    }

    &+label {
      background: url("/assets/img/ico/circle_check.svg") no-repeat 0 center / contain;
    }
  }
}


/* Input
  * --------------------------------------- */
input,
textarea {
  color: var(--color-text-regular);

  &::placeholder {
    color: var(--color-text-placeholder);
  }
}

.input {
  padding: 12px;
  line-height: 1.6;
  height: 40px;
  border: 1px solid var(--color-border) !important;
  outline: none;
  font-size: 15px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  background: white;
  box-sizing: border-box;

  &.input-lg,
  &.select-lg {
    height: 48px;
    line-height: 48px;
  }

  &::placeholder {
    color: var(--color-text-placeholder);
  }

  &:focus {
    border: 1px solid var(--color-primary) !important;
  }

  &.error {
    border: 1px solid var(--color-danger) !important;
    z-index: 9;
    transition: none;

    &.with-icon {
      background: url(/assets/img/ico/circle_check_wrong.svg) center right 12px no-repeat;
    }
  }

  &.disabled {
    background-color: var(--color-grey-100);
    color: var(--color-grey-500);
  }

  &.success {
    border: 1px solid var(--color-primary) !important;
    z-index: 9;
    transition: none;

    &.with-icon {
      background: url(/assets/img/ico/circle_check_bl_on.svg) center right 12px no-repeat;
    }
  }
}

.select-wrap {
  background: url(/assets/img/ico/input_arrow_down.svg) no-repeat center right 10px / contain;
  background-size: 20px;
  background-color: white;
}

.checkbox-wrap,
.radio-wrap {
  display: flex;
  align-items: center;

  >input {
    margin-right: 5px;
  }

  >label {
    cursor: pointer;
  }
}

.select {
  position: relative;
  min-width: 162px;

  &.select-sm {
    min-width: 102px;

    @media screen and (min-width: $mobile-l-width) {
      height: 30px;
      line-height: 30px;
      font-size: 14px;

      .label {
        height: 30px;
        line-height: 30px;
      }

      .option-wrap li {
        height: 30px;
      }
    }
  }

  .custom-label {
    display: inline-block;
    cursor: pointer;

    .filter-btn {
      background: url("/assets/img/ico/filter.svg") no-repeat center / cover;
      width: 24px;
      height: 24px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .label {
    position: relative;
    padding: 0 12px;
    height: 40px;
    line-height: 40px;
    border: 1px solid var(--color-grey-400);
    background-color: white;
    border-radius: 5px;
    overflow: hidden;


    &::after {
      display: block;
      content: ' ';
      position: absolute;
      margin-top: -10px;
      top: 50%;
      right: 10px;
      background: url(/assets/img/ico/input_arrow_down.svg) no-repeat center / contain;
      background-size: 20px;
      width: 20px;
      height: 20px;
      transition: all .2s ease-in-out;
    }
  }

  .title {
    padding: 0 16px;
    height: 52px;
    line-height: 52px;
    display: none;
    border-bottom: 1px solid var(--color-grey-200);

    .close-btn {
      position: absolute;
      top: 24px;
      right: 24px;
      border: none;
      outline: none;
      z-index: 9;
      cursor: pointer;

      @include icon-close(14px,
        2px,
        var(--color-flat-black),
        5px,
        inline-block,
        absolute,
        1px);
    }
  }

  .option-wrap {
    position: absolute;
    width: 100%;
    margin-top: 1px;
    display: none;
    z-index: 9;
    background: white;
    overflow: hidden;

    ul {
      width: 100%;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 38px;
        padding: 5px 16px;
        cursor: pointer;

        &.selected,
        &:hover {
          background-color: var(--color-grey-50);
        }
      }
    }
  }

  &.has-sub-option {
    .option-wrap {
      width: 200%;

      ul {
        float: left;
        width: 50%;

        &:nth-of-type(2) {
          li {
            background-color: var(--color-grey-50);

            &.selected,
            &:hover {
              background-color: var(--color-grey-100);
            }
          }
        }
      }
    }
  }

  &.show {
    .label {
      border: 1px solid var(--color-primary);

      &::after {
        transform: rotate(180deg)
      }
    }

    .option-wrap {
      display: block;
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {
    .title {
      display: block;
    }

    .mobile-bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      z-index: 9;
    }

    .option-wrap {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100% !important;
      max-height: 100%;
      border-radius: 0;
      z-index: 120;
      overflow-y: scroll;
      padding: 12px 0 24px;
      border-radius: 10px 10px 0 0;
      box-sizing: border-box;

      ul>li {
        justify-content: flex-start;
      }
    }
  }


  @media screen and (min-width: $mobile-l-width) {
    .option-wrap ul {
      overflow-y: auto;
    }

    &.show-title {
      .title {
        display: block;
      }
    }
  }
}