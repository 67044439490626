/* Width
* --------------------------------------- */
$desktop-xl-width: 2569px;
$desktop-l-width: 1920px;
$desktop-s-width: 1536px;
$tablet-s-width-1: 767px;
$tablet-s-width: 768px;
$tablet-l-width: 1024px;
$mobile-l-width-1: 1024px;
$mobile-l-width: 1025px;


/* mixin
* --------------------------------------- */
@mixin pc-only {
    @media all and (min-width: $tablet-l-width) {
        @content;
    }
}

@mixin mobile-only {
    @media screen and (min-width: $tablet-s-width) {
        @content;
    }
}

/* Class
* --------------------------------------- */
.mobile-only {
    @media all and (min-width: $mobile-l-width) {
        display: none !important;
    }
}

.pc-only {
    @media all and (max-width: $tablet-l-width) {
        display: none !important;
    }
}