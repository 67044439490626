/* Sizing
* --------------------------------------- */
.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.max-width-none {
  max-width: none !important;
}

/* Flex
* --------------------------------------- */
.flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-row-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


/* Typography
* --------------------------------------- */
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.bold {
  font-weight: 600 !important;
}

.fs-11 {
  font-size: 11px !important;
  line-height: 15px !important;
}

.fs-12 {
  font-size: 12px !important;
  line-height: 16px !important;
}

.fs-13 {
  font-size: 13px !important;
  line-height: 17px !important;
}

.fs-14 {
  font-size: 14px !important;
  line-height: 20px !important;
}

.fs-16 {
  font-size: 16px !important;
  line-height: 22px !important;
}

.fs-18 {
  font-size: 18px !important;
  line-height: 24px !important;
}

.fs-20 {
  font-size: 20px !important;
  line-height: 26px !important;
}

.fs-22 {
  font-size: 22px !important;
  line-height: 28px !important;
}

.fs-24 {
  font-size: 24px !important;
  line-height: 30px !important;
}

.fs-26 {
  font-size: 26px !important;
  line-height: 32px !important;
}

/* Spacing
* --------------------------------------- */
.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.my-20 {
  margin: 20px 0 !important;
}

.mx-10 {
  margin: 0 10px !important;
}

.mx-20 {
  margin: 0 20px !important;
}

.my-10 {
  margin: 10px 0 !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

/* Algin
* --------------------------------------- */
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.hide {
  display: none !important;
}

.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block !important;
}