.profile-edit-page {
  padding-bottom: 100px;

  .profile-box {
    gap: 30px;


    input {
      max-width: 358px;
    }

    @media screen and (max-width: $mobile-l-width-1) {
      gap: 20px;
      flex-flow: column;

      input {
        max-width: 100%;
      }

      .link {
        justify-content: center;
      }
    }
  }

  .profile-img-wrap {
    position: relative;
    overflow: visible;

    >.profile-img-wrap {
      overflow: hidden;
    }

    img {
      border-radius: 50px;
    }

    .select {
      position: absolute;
      right: 0;
      bottom: 0;

      @media screen and (min-width: $mobile-l-width) {
        .option-wrap {
          transform: translateX(100%)
        }
      }
    }
  }

  .tag-search-input {
    z-index: 9;
  }

  .page-foot-btn {
    margin-top: 30px;
  }

  .sns-list {
    >li {
      padding: 6px;
      display: flex;
      margin-bottom: 8px;
      align-items: center;
      height: 37px;
      line-height: 36px;
      background: var(--color-grey-50);

      >img {
        width: 42px;
      }

      >span {
        margin-left: 6px;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .sns-input-container {
    display: flex;
    box-shadow: border-box;
    gap: 20px;

    .right-area {
      display: flex;
      width: 100%;
      gap: 20px;

      .input {
        flex-grow: 1;
        width: 100%;
      }
    }

    @media screen and (min-width: $mobile-l-width) {
      .option-wrap {
        max-height: 218px;
        overflow-y: visible;
      }
    }

    @media screen and (max-width: $mobile-l-width-1) {
      flex-flow: column;
    }
  }
}