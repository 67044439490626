.create-studio-page {
  background-color: var(--color-grey-50);

  @media screen and (max-width: $mobile-l-width-1) {
    background-color: white;
  }

  .profile-container {
    margin: 50px 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .profile {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: var(--color-grey-200);
    }
  }

  .box-container {


    @media screen and (min-width: $mobile-l-width) {
      min-width: 1111px;

      .pc-text-center {
        text-align: center;
      }
    }

    @media screen and (max-width: $mobile-l-width-1) {
      padding: 0;

      .box {
        border: none;
      }
    }
  }



  .studio-key-title-wrap {
    display: flex;
    align-items: center;
    flex-flow: column;
    align-items: baseline;
    gap: 8px;
  }

  .box {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .same-company-list {
    margin-top: 2px;
    overflow: hidden;

    >li {
      position: relative;
      float: left;
      margin-top: 12px;
      margin-right: 12px;

      &::after {
        display: block;
        content: ' ';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 16px;
        height: 16px;
        background: url('/assets/img/ico/studio_create_next.svg') center no-repeat;
      }
    }
  }

  .box-area {
    display: flex;
    flex-flow: row;
    gap: 12px;

    @media screen and (max-width: $mobile-l-width-1) {
      flex-flow: column;
    }

    .box {
      margin: 0;
      padding: 0;
      width: 100%;


      .title-area {
        padding: 30px 24px;
      }

      &.box-left {
        padding: 0;
        text-align: center;

        .content-area {
          margin: 0 24px;
          border-top: 1px solid var(--color-grey-200);
          display: flex;
          flex-flow: row;

          @media screen and (max-width: $mobile-l-width-1) {
            // margin: 0 24px;
            flex-flow: column;
          }

          >div {
            padding: 30px 0 60px;
            text-align: center;
            width: 100%;

            &:first-child {
              padding-right: 24px;
            }

            &:last-of-type {
              padding-left: 24px;
              border-left: 1px solid var(--color-grey-200);

              @media screen and (max-width: $mobile-l-width-1) {
                padding-top: 24px;
                padding-left: 0;
                border-top: 1px solid var(--color-grey-200);
                border-left: none;
              }
            }

            .badge {
              background-color: var(--color-red-700);
              color: white;
              padding: 0 12px;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              display: inline-block;
              border-radius: 12px;
              font-weight: bold;
            }

            .free-badge {
              padding: 0 9px;
              display: inline-block;
              color: white;
              background-color: var(--color-red-700);
              line-height: 18px;
              border-radius: 9px;
            }

            .desc-list {
              text-align: left;

              >li {
                position: relative;
                margin-top: 12px;
                padding-left: 15px;

                &::before {
                  position: absolute;
                  content: '·';
                  font-weight: bold;
                  left: 0;
                }

                &:first-of-type {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: $mobile-l-width) {
      gap: 16px;

      .box-right {
        max-width: 298px;
      }
    }
  }

  .coming-soon-badge {
    display: inline-block;
    width: 108px;
    height: 24px;
    line-height: 24px;
    background-color: var(--color-grey-600);
    color: white;
    border-radius: 12px;
    font-size: 12px;
  }

  .red-stroke {
    position: relative;

    &::after {
      display: block;
      content: ' ';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--color-red-700);
    }
  }

  .plan-list {
    margin-top: 50px;

    >li {
      margin-bottom: 12px;
      text-align: left;

      ㅁ span {
        color: var(--color-red-700);
        margin-right: 13px;
        font-weight: 600;

        &::before {
          margin-right: 4px;
          display: inline-block;
          content: ' ';
          background: url(/assets/img/ico/pay_plus.svg) no-repeat center;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}