#root {
  position: fixed;
  display: flex !important;
  flex-flow: column !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

header {
  position: relative;
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;

  .header-container {
    height: 55px;
    z-index: 9;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-border-light);
    gap: 20px;
    z-index: 95;

    @media screen and (max-width: $mobile-l-width-1) {
      padding: 0;
      gap: 10px;
    }

    >div {
      display: flex;
      align-items: center;
      white-space: nowrap;

      &.left-area {
        padding-left: 14px;
        box-sizing: border-box;

        &::before {
          display: block;
          content: ' ';
          position: fixed;
          top: 0;
          left: 0;
          width: 270px;
          height: 58px;
          border-bottom: 1px solid var(--color-border-light);
          background-color: white;
          transform: translateX(-100%);
          transition: all .2s ease-in-out;
          z-index: -1;
        }

        @media screen and (max-width: $mobile-l-width-1) {
          padding-left: 6px;
        }

        .menu {
          width: 24px;
          height: 24px;
          cursor: pointer;
          padding: 10px;
        }

        .logo {
          position: relative;
          margin-left: 10px;
          cursor: pointer;
        }

        .category-area {
          margin-left: 4px;
          display: flex;
          align-items: center;

          .category {
            &.creative {
              width: 46px;
              height: 16px;
              background: url('/assets/img/ico/top_category_creative.svg') center bottom no-repeat;
            }

            &.tech {
              width: 26px;
              height: 16px;
              background: url('/assets/img/ico/top_category_tech.svg') center bottom no-repeat;
            }
          }

          .category-btn {
            margin-left: 6px;
            position: relative;
            width: 22px;
            height: 22px;
            background: url('/assets/img/ico/top_category_btn.svg') center no-repeat;
            border: 1px solid var(--color-grey-400);
            transition: all .2s ease-in-out;
            border-radius: 12px;
            z-index: 100;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                // background-color: var(--color-grey-50);
                background-color: red;
              }
            }

            ul {
              margin-top: 10px;
              top: 100%;
              left: -42px;
              width: 108px;
              position: absolute;
              background: #FFFFFF;
              border: 1px solid var(--color-grey-300);
              box-shadow: 0px 7px 20px rgba(54, 56, 57, 0.2);
              border-radius: 5px;
              z-index: 100;

              li {
                line-height: 48px;
                text-align: center;
                border-bottom: 1px solid var(--color-grey-300);
                transition: all .2s ease-in-out;

                &:first-of-type {
                  &:hover {
                    background-color: var(--color-purple-50);
                  }
                }

                &:nth-of-type(2) {
                  &:hover {
                    background-color: var(--color-green-50);
                  }
                }

                &:last-of-type {
                  border-bottom: none;

                  &:hover {
                    background-color: var(--color-grey-100);
                  }
                }
              }
            }
          }
        }

        .link-area {
          display: flex;
          align-items: center;

          height: 59px;

          a {
            line-height: 59px !important;
            height: 100%;
          }

          .active {
            box-shadow: inset 0 -2px 0 var(--color-flat-black);
            z-index: 9;
          }
        }
      }

      &.right-area {
        padding-right: 24px;

        @media screen and (max-width: $mobile-l-width-1) {
          padding-right: 16px;
        }

        .login-btn,
        .signup-btn {
          padding: 0;
          width: 162px;
          height: 40px;

          @media screen and (max-width: $mobile-l-width-1) {
            height: 30px;
            width: 70px;
          }
        }

        .info-btn {
          margin-left: 14px;
          border: none;
          outline: none;
          cursor: pointer;
          // &.has-noti {
          //   background: url(/assets/img/ico/info_noti.svg) no-repeat 0 center / cover;
          // }
        }

        .message-btn {
          width: 24px;
          height: 24px;
          background: url(/assets/img/ico/message.svg) no-repeat 0 center / cover;
          border: none;
          outline: none;
          cursor: pointer;

          &.has-noti {
            background: url(/assets/img/ico/message_noti.svg) no-repeat 0 center / cover;
          }
        }
      }
    }
  }

  .message-box {
    background-color: white;
    display: flex;
    flex-flow: column;
    min-height: 262px;
    z-index: 91;

    .close-btn {
      position: absolute;
      top: 24px;
      right: 24px;
      border: none;
      outline: none;
      background: transparent;
      cursor: pointer;
      z-index: 9;

      @include icon-close(16px,
        2px,
        var(--color-flat-black),
        4,
        inline-block,
        absolute,
        2);
    }

    .tabs {
      margin-top: 20px;
      // height: 70px;
      // border-bottom: 1px solid var(--color-grey-200);
      // display: flex;
      // flex-flow: row;
      // gap: 6px;
      // align-items: center;
      // padding: 0 24px;

      .badge {
        margin-left: 6px;
        min-width: 19px;
        height: 19px;
        border-radius: 9.5px;
        background: var(--color-red-700);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 11px;
      }
    }

    .message-list {
      flex-grow: 1;
      overflow-y: auto;

      >li {
        width: 100%;
        padding: 18px 24px;
        transition: all .2s ease-in-out;
        cursor: pointer;
        box-sizing: border-box;

        &:not(.auth-item):hover {
          background-color: var(--color-grey-50);
        }
      }
    }


    @media screen and (min-width: $mobile-l-width) {
      @include shadow();
      position: absolute;
      top: 52px;
      right: 62px;
      width: 420px;
      border-radius: 15px;

      .message-list {
        max-height: 280px;

      }
    }

    @media screen and (max-width: $mobile-l-width-1) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 200;
    }
  }


  .profile-box {
    background: white;
    z-index: 91;
    overflow: hidden;

    .box-menus {
      overflow: hidden;
      background: white;

      >li {
        width: 100%;
        height: 48px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
          background-color: var(--color-grey-50);
        }
      }
    }

    .user-profile-area {
      padding: 0 24px;
      background: white;
    }

    @media screen and (min-width: $mobile-l-width) {
      @include shadow();
      position: absolute;
      top: 52px;
      right: 25px;
      width: 326px;
      padding-bottom: 24px;
      border-radius: 15px;
    }

    @media screen and (max-width: $mobile-l-width-1) {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      border-radius: 10px 10px 0 0;
      overflow: hidden;

      .close-btn {
        position: absolute;
        top: 24px;
        right: 24px;
        border: none;
        outline: none;
        z-index: 9;
        cursor: pointer;

        @include icon-close(14px,
          2px,
          var(--color-flat-black),
          5px,
          inline-block,
          absolute,
          1px);
      }

      .box-menus {
        padding-bottom: 24px;
      }
    }
  }
}

aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 270px;
  height: 100%;
  background: white;
  z-index: -1;
  background: #ffffff;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  transform: translateX(-100%);
  overflow-y: auto;

  .head-area {
    @include layout-padding;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 56px;
    width: 100%;
    box-sizing: border-box;

    .profile-img-wrap {
      display: block;
      margin: 24px auto 0;
    }
  }

  .body-area {
    flex-grow: 1;

    nav {
      a {
        position: relative;
        display: block;
        padding: 0 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 3;
        color: var(--color-grey-700);
        transition: all 0.2s ease-in-out;

        &::before {
          position: absolute;
          top: 50%;
          right: 24px;
          display: block;
          content: " ";
          background: url(/assets/img/ico/sidebar_arrow.svg) center right no-repeat;
          background-size: 24px;
          width: 24px;
          height: 24px;
          transform: translateY(-50%);
        }

        &:hover,
        &:active,
        &.active {
          font-weight: 500;
          background-color: var(--color-grey-50);
        }
      }
    }
  }

  .foot-area {
    @include layout-padding;

    ul {
      overflow: hidden;
      width: 100%;
      margin-bottom: 10px;

      li {
        float: left;
        padding: 0 10px;
        border-left: 1px solid var(--color-grey-500);
        font-size: 12px;
        line-height: 16px;

        a {
          color: var(--color-grey-500);
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          &:hover {
            color: var(--color-grey-600);
          }
        }

        &:first-of-type {
          padding-left: 0;
          border-left: none;
        }
      }
    }
  }
}

footer {

  a {
    color: var(--color-grey-600);
    font-weight: 600;
    color: pointer;
    white-space: nowrap;
    font-size: 13px;
  }

  p.etc-info-link {

    cursor: pointer;
    color: var(--color-grey-600);
    font-weight: 600;
    white-space: nowrap;
    font-size: 13px;

    span {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .arrow-down {
      margin-left: 4px;
      width: 14px;
      height: 14px;
      display: inline-block;

      background: url(/assets/img/ico/footer_arrow_down.svg);

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .etc-layer {
    color: var(--color-grey-600);
    font-size: 12px;
    line-height: 22px;

    .tail {
      position: absolute;
      content: ' ';
      bottom: -10px;
      left: 112px;
      @include icon-triangle('down', 24px, 20px, white, block);
    }
  }

  .sns-link {

    a {
      margin-right: 6px;
      color: transparent;
      display: inline-block;
      width: 36px;
      height: 36px;

      &.youtube {
        background: url(/assets/img/ico/footer_sns_youtube.svg) no-repeat center;
        background-size: cover;
      }

      &.instagram {
        background: url(/assets/img/ico/footer_sns_insta.svg) no-repeat center;
        background-size: cover;
      }

      &.facebook {
        background: url(/assets/img/ico/footer_sns_facebook.svg) no-repeat center;
        background-size: cover;
      }

      &.linkedin {
        background: url(/assets/img/ico/footer_sns_linkdin.svg) no-repeat center;
        background-size: cover;
      }

      &.naverblog {
        background: url(/assets/img/ico/footer_sns_naver_blog.svg) no-repeat center;
        background-size: cover;
      }
    }
  }
}

aside footer {
  margin-top: 30px;

  .etc-layer {
    margin-top: 14px;
  }

  .service-link {
    margin-top: 30px;
    display: flex;
    flex-flow: column;
    gap: 14px;
  }

  .copyright {
    margin-top: 30px;
  }

  .sns-link {
    margin-top: 16px;
  }
}

#root>footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 36px;
  padding: 0 24px;
  background: white;
  display: flex;
  font-size: 13px;
  line-height: 17px;
  border-top: 1px solid var(--color-grey-300);
  white-space: nowrap;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  z-index: 90;

  .etc-layer {
    position: fixed;
    bottom: 50px;
    background: white;
    padding: 20px;
    @include shadow();
  }

  >div {
    display: flex;
    align-items: center;
  }

  .service-link {
    margin-left: 30px;
    border-left: 1px solid var(--color-grey-600);
    overflow: hidden;
    display: flex;

    a {
      margin-left: 30px;
    }
  }

  .sns-link {
    margin-left: 39px;
  }

  @media screen and (max-width: $mobile-l-width-1) {
    display: none;
  }
}

main {
  height: 100%;
  overflow-y: auto;

  .page {
    overflow: auto;
    min-height: 100%;
  }
}


/* 박스 컨테이너
* --------------------------------------- */
.box-container {
  margin: 40px auto 80px;
  width: 100%;
  max-width: 924px;
  display: block;
  box-sizing: border-box;

  @media screen and (max-width: $mobile-l-width-1) {
    @include layout-padding;

    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  .box {
    margin-top: 12px;
    position: relative;
    padding: 30px;
    width: 100%;
    background: white;
    box-sizing: border-box;
    border: 1px solid var(--color-grey-100);

    &:first-of-type {
      margin-top: 0;
    }

    @media screen and (max-width: $mobile-l-width-1) {
      margin-top: 16px;
    }
  }

  .box-wrap {
    .box {
      margin: 0;
    }
  }

  .box-inner-container {
    width: 100%;

    &.box-inner-container-sm {
      max-width: 358px;
    }

    &.box-inner-container-md {
      max-width: 590px;
    }

    &.box-inner-container-lg {
      max-width: 724px;
    }
  }
}

header {
  .search-bar {
    position: relative;
    width: 100%;
    max-width: 610px;
    height: 40px;
    display: flex;
    align-items: center;
    background: var(--color-grey-100);
    border: 1px sold var(--color-grey-100);
    border-radius: 5px;
    transition: all .2s ease-in-out;

    &.focus {
      background: white;
      border: 1px solid var(--color-blue);

      .search-btn {
        background: url(/assets/img/ico/search_bk.svg) center no-repeat;
        background-size: contain;
      }
    }

    .search-btn {
      display: block;
      content: ' ';
      position: absolute;
      top: 8px;
      left: 12px;
      background: url(/assets/img/ico/search_gr.svg) center no-repeat;
      background-size: contain;
      border: none;
      outline: none;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    input {
      margin: 0 12px 0 46px;
      width: 100%;
      height: 22px;
      border: none;
      background: none;
      outline: none;
    }


    .category-area {
      border-left: 1px solid var(--color-grey-400);
      padding-left: 14px;
      padding-right: 12px;
      display: flex;
      gap: 6px;

      button {
        padding: 0 6px;
        border: none;
        outline: none;
        border-radius: 20px;
        line-height: 28px;
        font-size: 14px;
        color: var(--color-flat-black);
        transition: all .2s ease-in-out;
        cursor: pointer;
        font-weight: 600;
        white-space: nowrap;

        &.active {
          background-color: var(--color-flat-black);
          color: white;
        }
      }
    }
  }

  .m-search-bar {
    // position: absolute;
    @include layout-padding;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 100%;
    height: 48px;
    display: flex;
    flex-flow: row;
    align-items: center;
    border-bottom: 1px solid var(--color-border-light);
    box-sizing: border-box;
    background: white;
    z-index: 9;

    .search-btn {
      background: url(/assets/img/ico/search_gr.svg) no-repeat center / cover;
      width: 24px;
      height: 24px;
      outline: none;
      border: none;
    }

    input {
      width: 100%;
      height: 100%;
      margin-left: 10px;
      outline: none;
      border: none;
      background: transparent;
    }


    .category-area {
      border-left: 1px solid var(--color-grey-400);
      padding-left: 14px;
      padding-right: 0;
      display: flex;
      gap: 6px;

      button {
        padding: 0 6px;
        border: none;
        outline: none;
        border-radius: 20px;
        line-height: 28px;
        font-size: 14px;
        color: var(--color-flat-black);
        transition: all .2s ease-in-out;
        cursor: pointer;
        font-weight: 600;
        white-space: nowrap;

        &.active {
          background-color: var(--color-flat-black);
          color: white;
        }
      }
    }
  }


  @media screen and (min-width: $mobile-l-width) {
    .m-search-bar {
      display: none !important;
    }

    .search-result-box {
      position: absolute;
      padding: 20px 0;
      top: 42px;
      left: 0;
      width: 100%;
      z-index: 92;
      @include shadow();

      li {
        height: 48px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        background: white;
        transition: all .2s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: var(--color-grey-50);
        }
      }
    }
  }


  @media screen and (max-width: $mobile-l-width-1) {
    .search-bar {
      display: none !important;
    }

    .search-result-box {
      position: fixed;
      padding: 0;
      top: 104px;
      left: 0;
      width: 100%;
      bottom: 0;
      background-color: white;
      z-index: 92;

      li {
        height: 48px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        background: white;
        transition: all .2s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: var(--color-grey-50);
        }
      }
    }
  }
}


/* 좌측 메뉴 보이는 경우
* --------------------------------------- */
.show-aside {

  aside {
    opacity: 1;
    transform: translateX(0);
    z-index: 100;
    box-shadow: 7px 0px 20px rgba(0, 6, 4, 0.1);
  }

  header .left-area {
    z-index: 110;

    .link-area {
      display: none !important;
    }

    &::before {
      transform: none !important;
    }

    .menu .icon {
      @include icon-close(14px,
        2px,
        var(--color-flat-black),
        0,
        inline-block,
        absolute);
    }
  }

  #root>footer {
    display: none;
  }

  .container {
    transition: all 0.2s ease-in-out;

  }

  @media screen and (min-width: $mobile-l-width) {
    .container {
      padding-left: 300px !important;
      box-sizing: content-box;
    }
  }

  @media screen and (max-width: $mobile-l-width-1) {
    #root::before {
      display: block;
      content: ' ';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 11;
    }
  }
}

body:not(.show-aside) .menu .icon {
  @include icon-hamburger(18px, 2px, 4px, var(--color-flat-black));
}

/* 좌측 메뉴 보이는 경우
* --------------------------------------- */
.page-foot-btn {
  margin-top: 30px;

  @media screen and (max-width: $mobile-l-width-1) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    background: white;
    padding: 20px 16px;
    box-sizing: border-box;
    z-index: 10;
    @include shadow();
  }
}

.btn-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;

  >* {
    width: 100%;
  }

  @media screen and (min-width: $mobile-l-width) {
    .btn {
      max-width: 174px;
    }
  }
}