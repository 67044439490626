@import "components/button";
@import "components/icon";
@import "components/input";
@import "components/popup";
@import "components/profile";
@import "components/search_input";
@import "components/spinner";
@import "components/tag";
@import "components/tabs";

/* Inline
  * --------------------------------------- */
.hr {
  margin: 0;
  border-top: 1px solid var(--color-border);

  &.black {
    border-top: 1px solid var(--color-border-dark);
  }
}

.shadow {
  @include shadow();
}

.shadow-blue {
  @include shadow-blue();
}

.shadow-pink {
  @include shadow-pink();
}

.link {
  cursor: pointer !important;
  color: var(--color-primary);

  &:hover {
    color: var(--color-primary-light);
  }
}

/* 원형 페이지네이션
* --------------------------------------- */
ul.circle-pagination {
  display: flex;
  justify-content: center;
  gap: 16px;

  li {
    width: 6px;
    height: 6px;
    background: var(--color-grey-300);
    border-radius: 3px;

    &.active {
      background: var(--color-red-700);
    }
  }
}

/* 정렬 필터
* --------------------------------------- */
.order-filters {

  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  gap: 8px;

  >li {
    color: var(--color-grey-600);
    font-weight: 600;
    cursor: pointer;

    &.active {
      color: var(--color-flat-black);
    }
  }
}

.category-badge.banner span {
  background: #FFE4EC !important;
  color: var(--color-red-700) !important;
}