div.title {
  background-color: #ffffff;
  padding: 21px 16px;

  >textarea {
    padding-right: 52px;
    display: block;
    color: inherit;
    font-family: inherit;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    resize: none;
    text-overflow: ellipsis;

    &::placeholder {
      color: #adb2b4;
      font-size: inherit;
    }
  }

  &.menuOpened>textarea {
    padding-right: 154px;
  }
}