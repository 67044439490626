.more-info-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 270px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 16.55%);
    z-index: 91;
}

@media screen and (max-width: $mobile-l-width-1) {
    .show-main-popup .more-info-banner {
        display: none;
    }
}