.video {
  display: flex;
  min-height: 14.75rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.25rem;
  background: #edeff0;
  input[type="text"] {
    background: #ffffff;
    border: 1px solid #d9dee0;
    border-radius: 5px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.4285714285714286;
    color: #000604;
    padding: 0.625rem 0.75rem;
    width: 590px;
    max-width: calc(100% - 4rem);
    &::placeholder {
      color: #adb2b4;
    }
  }
}
