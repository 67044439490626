.imageBlock {
  min-height: 14.75rem;
  background: #edeff0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .imageWrapper {
    // overflow: hidden;
    position: relative;

    >img {
      display: block;
      width: 100%;
    }

    .clickLayer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  >.captionGuide {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.4285714285714286;
    color: #e60040;
    text-align: left;
    background: #ffffff;
    margin: 0;
    width: 100%;
    padding: 0.625rem 1rem;
    box-sizing: border-box;
  }

  >.uploadGuide {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    >strong {
      margin-bottom: 1rem;
      font-weight: 600;
      line-height: 1.375;
    }

    >p {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.4285714285714286;
      color: #414244;
      margin-bottom: 1.25rem;
    }

    >input[type="file"] {
      display: none;
    }
  }
}