.request-subs-form {
  max-width: 924px;

  .box-wrap {
    margin-top: 30px;
    display: flex;
    width: 100%;
    gap: 12px;

    .box {
      padding: 30px;
      margin: 0;
    }

    .sale-box {
      width: 100%;
      height: 90px;
      display: flex;
      padding: 0 24px;
      box-sizing: border-box;
      align-items: center;
      justify-content: flex-end;
      background: var(--color-blue-50);
      border: 1px solid var(--color-blue-500);
      gap: 8px;

      .badge {
        background-color: var(--color-red-700);
        color: white;
        padding: 0 8px;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        font-weight: bold;
        font-size: 12px;
      }

      .raw-price {
        font-size: 26px;
        font-weight: 600;
        text-decoration: line-through;
        color: var(--color-grey-400);
      }

      .sale-price {
        font-size: 26px;
        font-weight: 600;
        color: var(--color-blue-500);
      }
    }

    .desc-box {
      background-color: var(--color-grey-50);
      padding: 16px;
      max-height: 146px;
      overflow-y: scroll;
    }

    @media screen and (min-width: $mobile-l-width) {
      >.box:first-of-type {
        min-width: 522px;
        flex-grow: 1;
      }
    }

    @media screen and (max-width: $mobile-l-width-1) {
      flex-flow: column;
    }
  }
}

.payment-complete-page {
  .container {
    display: flex;
    width: 100%;
    max-width: 924px;
    gap: 12px;
    margin: 30px auto 50px;

    @media screen and (max-width: $mobile-l-width-1) {
      margin: 0 auto;
      gap: 0;
      flex-flow: column;
      background-color: var(--color-grey-100);
    }

    .box {
      padding: 30px;
      margin: 0;
      background: white;
      width: 522px;
    }


    .receipt-box {
      padding: 0 0 30px !important;
      width: 390px;
      min-width: 390px;
      box-sizing: border-box;
      background-color: var(--color-grey-100);
      display: flex;
      flex-flow: column;

      @media screen and (max-width: $mobile-l-width-1) {
        margin-left: 50%;
        transform: translateX(-50%);
        margin-bottom: 60px;
      }

      .receipt-content-area {
        position: relative;
        margin-top: 55px;
        display: flex;
        flex-flow: column;
        background: url(/assets/img/receipt.svg) center top no-repeat;
        background-size: contain;
        padding: 45px;
        height: 100%;
        box-sizing: border-box;
        min-height: 460px;

        @media screen and (max-width: $mobile-l-width-1) {
          margin-top: 15px;
        }

        .receipt-box-body {
          height: 258px;

          .price-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            >p:last-of-type {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }

          .badge {
            background-color: var(--color-red-700);
            color: white;
            padding: 0 8px;
            height: 24px;
            line-height: 24px;
            border-radius: 12px;
            font-weight: bold;
            font-size: 12px;
          }


          .raw-price {
            font-size: 26px;
            font-weight: 600;
            text-decoration: line-through;
            color: var(--color-grey-400);
          }

          .sale-price {
            font-size: 26px;
            font-weight: 600;
            color: var(--color-blue-500);
          }
        }

        .receipt-box-foot {
          height: 100px;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}