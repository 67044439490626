.plan-page {
  .payment-list {
    >li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-border);
      height: 48px;

      .badge {
        display: inline-block;
        margin-left: 15px;
        color: white;
        background: var(--color-red-700);
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        padding: 0 12px;
      }

      .discount-price {
        position: relative;
        color: var(--color-grey-400);

        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 1px;
          display: block;
          content: ' ';
          background-color: var(--color-grey-400);
          font-size: 16px;
        }
      }

      .price {
        margin-left: 4px;
        font-weight: bold;
      }

      .price-unit {
        margin-left: 6px;
      }

      .link {
        margin-left: 20px;
        text-decoration: underline;
      }
    }
  }
}